import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Paths } from 'app/config';
import { Disclaimer, Footer, PoliciesNav, Privacy, Terms } from '.';

function Policies() {
    return (
        <div className="policies">
            <div className="inner container">
                <div className="cols">
                    <PoliciesNav/>
                    <div className="col">
                        <Switch>
                            <Route
                                exact path={Paths.absolute('policyDisclaimer')}
                                render={() => <Disclaimer/>}
                            />
                            <Route
                                exact path={Paths.absolute('policyPrivacy')}
                                render={() => <Privacy/>}
                            />
                            <Route
                                exact path={Paths.absolute('policyTerms')}
                                render={() => <Terms/>}
                            />
                            <Redirect to={Paths.absolute('root')}/>
                        </Switch>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Policies;
