import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Expired, Form, Password } from '.';
import { Paths } from 'app/config';

function RequestAccess() {
    return (
        <div className="request-access container">
            <Switch>
                <Route
                    path={Paths.absolute('accessRequest')}
                    render={routerProps => <Form {...routerProps}/>}
                />
                <Route
                    path={Paths.absolute('accessSetPassword', ':email')}
                    render={routerProps => <Password {...routerProps}/>}
                />
                <Route
                    path={Paths.absolute('accessExpired', ':email')}
                    render={routerProps => <Expired {...routerProps}/>}
                />
                <Redirect to={Paths.absolute('root')}/>
            </Switch>
        </div>
    );
}

export default RequestAccess;
