import React from 'react';

function DataCard({data}) {
    return (
        <div className="card" style={{overflowX: 'scroll'}}>
            <pre className="inner">
                {JSON.stringify(data, null, 4)}
            </pre>
        </div>
    );
}

export default DataCard;
