import React from 'react';
import { NavLink } from 'react-router-dom';
import { Paths } from 'app/config';
import { IconStorefront } from 'app/assets/icons';
import categories from 'app/data/licenseCategories';

function ProfileNav() {
    return <>
        <NavLink
            exact to={Paths.route('profiles')}
            className="nav-link"
            activeClassName="active"
            isActive={(match, location) => location.pathname.startsWith('/profile')}>
            <span className="icon"><IconStorefront/></span>
            <span className="label">Profiles</span>
        </NavLink>
        <div className="sub-nav">
            {Object.keys(categories).map(key =>
                <NavLink
                    key={key}
                    to={Paths.route('profiles', key)}
                    className="nav-link"
                    activeClassName="active">
                    <span className="label">
                        {categories[key].label}
                    </span>
                </NavLink>
            )}
        </div>
    </>;
}

export default ProfileNav;
