import React from 'react';
import { withToastManager } from 'react-toast-notifications';
import { toast } from 'app/lib/helpers';
import validate from 'app/lib/validate';
import { IconUnlock } from 'app/assets/icons';
import { CommonForm } from 'app/components/common';
import { FormFooter } from '..';
import { Fields } from '.';
import { emailAuthProvider, reauthenticateWithCredential, updatePassword } from 'app/lib/firebaseMethods';

const initialFormData = {
    current_password: '',
    new_password: ''
};

class Password extends CommonForm {
    state = {
        ...this.state,
        formData: initialFormData
    };
    validators = () => [
        ...validate.commonText('current_password', this.state.formData.current_password),
        ...validate.password('new_password', this.state.formData.new_password)
    ];
    submitForm = () => {
        const credentials = emailAuthProvider(this.props.user.email, this.state.formData.current_password);
        this.handleReauthenticate(credentials);
    }
    handleReauthenticate = credentials => {
        reauthenticateWithCredential(credentials).then(r => {
            console.log('Settings/Password :: reauthenticateWithCredential ::', r);
            this.handleUpdatePassword();
        }).catch(error => {
            console.log('Settings/Password :: reauthenticateWithCredential [Error] ::', error);
            const { authFailedAttempts, authIncorrectPassword } = validate.messages;
            const message = error.code !== 'auth/too-many-requests' ? authIncorrectPassword : authFailedAttempts;
            this.setState({disabled: false, errors: [{name: 'current_password', message}]});
        });
    }
    handleUpdatePassword = () => {
        updatePassword(this.state.formData.new_password).then(r => {
            console.log('Settings/Password :: updatePassword ::', r);
            this.setState({disabled: false, errors: null, formData: initialFormData});
            this.props.toastManager.add(...toast("Success! Your password has been updated."));
        }).catch(error => {
            const errors = validate.dataErrors('Settings/Password :: updatePassword [Error] ::', error);
            this.setState({disabled: false, errors});
        });
    }
    render() {
        const { disabled, errors, formData } = this.state;
        return (
            <div className="card">
                <form onSubmit={this.handleSubmit}>
                    <h3 className="form-title has-icon">
                        <span className="icon">
                            <IconUnlock/>
                        </span>
                        Password
                    </h3>
                    <Fields
                        disabled={disabled}
                        errors={errors}
                        formData={formData}
                        handleChange={this.handleChange}
                    />
                    <FormFooter
                        disabled={disabled || !formData.current_password || !formData.new_password}
                        errors={errors}
                        label="Update Password"
                    />
                </form>
            </div>
        );
    }
}

export default withToastManager(Password);
