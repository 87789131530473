import React from 'react';

function FormErrors({errors}) {
    if (Array.isArray(errors) && errors.length) {
        const _errors = errors.filter(error => !error.name);
        if (!_errors.length) _errors.push({message: 'this form contains errors'});
        return (
            <ul className="form-errors">
                {_errors.map((error, i) =>
                    <li key={i} className="error">{error.message}</li>
                )}
            </ul>
        );
    }
    else return null;
}

export default FormErrors;
