import React from 'react';

function ExternalLink({children, className, href}) {
    return (
        <a className={className}
            href={!href.includes('://') ? `https://${href}` : href}
            target="_blank"
            rel="noopener noreferrer">
            {children || href}
        </a>
    );
}

export default ExternalLink;
