import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { Loading } from 'app/components/common';
import { ProfileImage } from 'app/components/Profile/Home';
import { OwnerActions } from '.';
import { messagePath } from 'app/components/Messages';
import { getResizedImageMetadata } from 'app/lib/firebaseMethods';

class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            profile: null,
            imageMetadata: null
        };
    }
    componentDidMount() {
        this.getProfile();
    }
    getProfile = () => {
        axios.get(Paths.service('profile', this.props.data.uid)).then(r => {
            console.log('Product/Home/SideBar :: getProfile ::', r.data);
            this.setState({profile: r.data}, () => {
                if (r.data.image) this.getImageMetadata(r.data);
                else this.setState({loading: false});
            });
        }).catch(error => {
            validate.dataErrors('Product/Home/SideBar :: getProfile [Error] ::', error);
            this.setState({loading: false});
        });
    }
    getImageMetadata = profile => {
        const bucket = Paths.storage('profileImage', profile.uid);
        getResizedImageMetadata(bucket, profile.image, 600).then(r => {
            console.log('Product/Home/SideBar :: getImageMetadata ::', r);
            this.setState({imageMetadata: r.customMetadata, loading: false});
        });
    }
    render() {
        const { data, history, user } = this.props;
        const { loading, profile, imageMetadata } = this.state;
        return (
            <div className="side-bar">
                <div className="inner">
                    {loading && <Loading padded/>}
                    {!loading && profile &&
                        <div className="profile">
                            <Link to={Paths.route('profileHome', profile.uid)}>
                                <ProfileImage data={profile} imageMetadata={imageMetadata}/>
                            </Link>
                            <Link className="title incognito" to={Paths.route('profileHome', profile.uid)}>
                                {profile.business_name}
                            </Link>
                            <div className="actions">
                                {data.uid === user.user_id ?
                                    <OwnerActions {...this.props}/>
                                    :
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => history.push(messagePath(user.user_id, profile))}>
                                        Send Message
                                    </button>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default SideBar;
