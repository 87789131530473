const messages = require('./messages');

module.exports = {
    "auth/email-already-exists": {
        name: "email",
        message: messages.emailUnavailable
    },
    "auth/email-already-in-use": {
        name: "email",
        message: messages.emailUnavailable
    },
    "auth/user-not-found": {
        name: '',
        message: messages.authCredentialsInvalid
    },
    "auth/wrong-password": {
        name: '',
        message: messages.authCredentialsInvalid
    }
};
