import { SET_CONFIG } from '../actions/config';

const initialState = {
    applicationName: 'Evernug'
};

const config = (state = initialState, action) => {
    switch (action.type) {

        case SET_CONFIG:
            return {
                ...state,
                ...action.config
            };

        default:
            return state;
    }
};

export default config;
