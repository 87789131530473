import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import validate from 'app/lib/validate';
import store from 'redux/store';
import { setConfig } from 'redux/actions/config';

export async function getIdTokenResult() {
    let user = await firebase.auth().currentUser;
    let bearer = null;
    let expiry = null;
    if (user) {
        try {
            const r = await firebase.auth().currentUser.getIdTokenResult(true);
            if (r?.claims) user = r.claims;
            if (r?.token) bearer = r.token;
            if (r?.expirationTime) expiry = Date.parse(r.expirationTime);
        }
        catch(err) {
            validate.dataErrors('getIdTokenResult [Error] ::', err);
        }
    }
    store.dispatch(setConfig({bearer, bearerExpiry: expiry}));
    return user;
}

export function isEmailAvailable(email) {
    return firebase.auth().fetchSignInMethodsForEmail(email).catch(error => {
        validate.dataErrors('isEmailAvailable [Error] ::', error);
    });
}

export function emailAuthProvider(email, password) {
    return firebase.auth.EmailAuthProvider.credential(email, password);
}

export function reauthenticateWithCredential(credential) {
    return firebase.auth().currentUser.reauthenticateWithCredential(credential);
}

export function updatePassword(password) {
    return firebase.auth().currentUser.updatePassword(password);
}

export function applyActionCode(code) {
    return firebase.auth().applyActionCode(code);
}

export function storeFile(bucket, fileName, file) {
    const ref = firebase.storage().ref();
    const childRef = ref.child(`${bucket}/${fileName}`);
    return childRef.put(file).catch(error => {
        validate.dataErrors('storeFile [Error] ::', error);
    });
}

export function getFileURL(bucket, fileName) {
    const ref = firebase.storage().ref();
    const childRef = ref.child(`${bucket}/${fileName}`);
    return childRef.getDownloadURL().catch(error => {
        validate.dataErrors('getFileURL [Error] ::', error);
    });
}

export function getFileMetadata(bucket, fileName) {
    const ref = firebase.storage().ref();
    const childRef = ref.child(`${bucket}/${fileName}`);
    return childRef.getMetadata().catch(error => {
        validate.dataErrors('getFileMetadata [Error] ::', error);
    });
}

export function deleteFile(bucket, fileName) {
    const ref = firebase.storage().ref();
    const childRef = ref.child(`${bucket}/${fileName}`);
    return childRef.delete().catch(error => {
        validate.dataErrors('deleteFile [Error] ::', error);
    });
}

export function getResizedImageURL(bucket, fileName, size = 900) {
    const parts = fileName.split('.');
    const imageName = `${parts[0]}_${size}.${parts[1]}`;
    const ref = firebase.storage().ref();
    const childRef = ref.child(`${bucket}/${imageName}`);
    return childRef.getDownloadURL().catch(error => {
        if (error.code.endsWith('object-not-found')) {
            return getFileURL(bucket, fileName);
        }
    });
}

export function getResizedImageMetadata(bucket, fileName, size = 900) {
    const parts = fileName.split('.');
    const imageName = `${parts[0]}_${size}.${parts[1]}`;
    const ref = firebase.storage().ref();
    const childRef = ref.child(`${bucket}/${imageName}`);
    return childRef.getMetadata().catch(error => {
        if (error.code.endsWith('object-not-found')) {
            return getFileMetadata(bucket, fileName);
        }
    });
}

export function deleteImageAllSizes(bucket, fileName, sizes = [300, 600, 900]) {
    const promises = sizes.map(size => {
        const parts = fileName.split('.');
        const imageName = `${parts[0]}_${size}.${parts[1]}`;
        const ref = firebase.storage().ref();
        const childRef = ref.child(`${bucket}/${imageName}`);
        return childRef.delete().catch(error => {
            validate.dataErrors('deleteImageAllSizes [Error] ::', error);
        });
    });
    promises.push(deleteFile(bucket, fileName));
    return promises;
}

export function initFirestore() {
    const db = firebase.firestore();
    if (process.env.NODE_ENV === 'development') {
        db.settings({host: "localhost:8080", ssl: false});
    }
    return db;
}
