import React, { useEffect, useState } from 'react';
import { IconClose } from 'app/assets/icons';
import { getResizedImageURL } from 'app/lib/firebaseMethods';

function Image({bucket, fileName, markImageForDeletion}) {
    const [src, setSrc] = useState(null);

    useEffect(() => {
        getResizedImageURL(bucket, fileName, 300).then(s => setSrc(s));
    }, [bucket, fileName]);

    return !src ? null : (
        <div className="file-drop">
            <div className="cols">
                <div className="col col-label">
                    <div className="label ellipsis">
                        {fileName}
                    </div>
                </div>
                <div className="icon remove" onClick={markImageForDeletion}>
                    <IconClose/>
                </div>
            </div>
            <div className="image-preview">
                <img src={src} alt=""/>
            </div>
        </div>
    );
}

export default Image;
