import React from 'react';
import { FileDrop } from 'app/components/common';
import { Image } from '.';

function ImageGroup(props) {
    const { formData, liftState } = props;
    return (
        <div className="images">
            <div className="field-group">
                <label>Image</label>
            </div>
            <div className="field-group">
                {formData.image
                    ? <Image
                        bucket={props.bucket}
                        fileName={formData.image}
                        markImageForDeletion={props.markImageForDeletion}
                    />
                    : <FileDrop
                        files={props.files}
                        onDrop={files => liftState({files})}
                        onRemove={() => liftState({files: []})}
                        disabled={props.disabled}
                        label="Drop or click"
                    />
                }
            </div>
        </div>
    );
}

export default ImageGroup;
