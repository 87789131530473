import React, { Component } from 'react';
import Tippy from '@tippyjs/react';
import { NavLink } from 'react-router-dom';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { IconChatBubble, IconCog, IconSignOut, IconStorefront } from 'app/assets/icons';
import { initFirestore } from 'app/lib/firebaseMethods';

class SignedIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            unreadThreads: 0
        };
    }
    componentDidMount() {
        this.subscribeToUnreadThreads();
        if (!this.props.gated) this.getProfile();
    }
    componentWillUnmount() {
        if (typeof this.unsubscribe === 'function') this.unsubscribe();
    }
    subscribeToUnreadThreads = () => {
        const db = initFirestore();
        const query = ['unread', '==', this.props.user.user_id];
        this.unsubscribe = db.collection('messageThreads').where(...query).onSnapshot(qs => {
            console.log('Nav/SignedIn :: subscribeToUnreadThreads ::', qs.size);
            this.setState({unreadThreads: qs.size});
        }, error => console.log('Nav/SignedIn :: subscribeToUnreadThreads [Error] ::', error));
    }
    getProfile = () => {
        axios.get(Paths.service('profile', this.props.user.user_id)).then(r => {
            console.log('Nav/SignedIn :: getProfile ::', r.data);
            this.setState({profile: r.data});
        }).catch(error => {
            validate.dataErrors('Nav/SignedIn :: getProfile [Error] ::', error);
        });
    }
    render() {
        const { gated, user } = this.props;
        const { profile, unreadThreads } = this.state;
        return <>
            {!gated && <>
                <NavLink
                    to={Paths.route('profileHome', user.user_id)}
                    className="nav-link"
                    activeClassName="active">
                    <Icon content={profile?.business_name || 'Profile'} icon={<IconStorefront/>}/>
                </NavLink>
                <NavLink
                    to={Paths.route('messages')}
                    className="nav-link messages-link has-indicator"
                    activeClassName="active">
                    <Icon content="Messages" icon={<IconChatBubble/>}/>
                    {!!unreadThreads &&
                        <span className="indicator-count has-danger-bg">{unreadThreads}</span>
                    }
                </NavLink>
                <NavLink
                    to={Paths.route('settings')}
                    className="nav-link"
                    activeClassName="active">
                    <Icon content="Settings" icon={<IconCog/>}/>
                </NavLink>
            </>}
            <NavLink
                to={Paths.route('userSignout')}
                className="nav-link"
                activeClassName="active">
                <Icon content="Sign Out" icon={<IconSignOut/>}/>
            </NavLink>
        </>;
    }
}

const Icon = ({content, icon}) => (
    <Tippy content={content} delay={[650, 0]} theme="small">
        <span className="icon">{icon}</span>
    </Tippy>
);

export default SignedIn;
