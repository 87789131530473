import React from 'react';

function ContactEmail({className}) {
    const a = ['o', 'f', 'n', 'i'];
    const b = ['g', 'u', 'n', 'r', 'e', 'v', 'e'];
    const c = ['m', 'o', 'c'];
    const d = arr => arr.reverse().map(k => k).join('');
    const e = `${d(a)}@${d(b)}.${d(c)}`;
    const f = ['o', 't', 'l', 'i', 'a', 'm'];
    return <a className={className} href={`${d(f)}:${e}`}>{e}</a>;
}

export default ContactEmail;
