import React, { Component } from 'react';
import axios from 'app/lib/axios';
import { Paths } from 'app/config';
import { DataCard, FieldGroup, FormErrors } from 'app/components/common';
import validate from 'app/lib/validate';

class UserLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            errors: null,
            user: null
        };
    }
    getUser = e => {
        e.preventDefault();
        this.setState({disabled: true, errors: null, user: null});
        axios.get(Paths.service('userLookup', e.target.email.value)).then(r => {
            console.log('Logout/UserLookup :: getUser ::', r.data);
            this.setState({disabled: false, user: r.data});
        }).catch(error => {
            const errors = validate.dataErrors('Logout/UserLookup :: getUser [Error] ::', error);
            this.setState({
                disabled: false,
                errors: errors.map(err => ({code: err.code, message: err.message}))
            });
        });
    }
    render() {
        const { disabled, user } = this.state;
        return (
            <div className="user-lookup">
                <div className="common-header cols">
                    <h2 className="col">User Lookup</h2>
                </div>
                <div className="form-container">
                    <form onSubmit={this.getUser}>
                        <FieldGroup>
                            <input type="email"
                                name="email"
                                className="floating"
                                disabled={disabled}
                                placeholder="Enter user's email address..."
                                autoFocus required/>
                            <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={disabled}>
                                Submit
                            </button>
                        </FieldGroup>
                    </form>
                </div>
                <FormErrors errors={this.state.errors}/>
                {user && <DataCard data={user}/>}
            </div>
        );
    }
}

export default UserLookup;
