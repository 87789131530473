import React, { useState } from 'react';

function Actions(props) {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const { action, deleteServiceRequest, disabled, history } = props;
    return (
        <div className="actions">
            {confirmDelete && <>
                <span className="confirm-message text-small">
                    This action is permanent. Are you sure?
                </span>
                <div className="btn-group">
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        disabled={disabled}
                        onClick={() => setConfirmDelete(false)}>
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-danger"
                        disabled={disabled}
                        onClick={deleteServiceRequest}>
                        DELETE
                    </button>
                </div>
            </>}
            {!confirmDelete &&
                <div className="btn-group">
                    {action === 'edit' &&
                        <button
                            type="button"
                            className="btn btn-outline-danger"
                            disabled={disabled}
                            onClick={() => setConfirmDelete(true)}>
                            Delete
                        </button>
                    }
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        disabled={disabled}
                        onClick={history.goBack}>
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={disabled}>
                        {action === 'edit' ? 'Save Changes' : 'Submit'}
                    </button>
                </div>
            }
        </div>
    );
}

export default Actions;
