import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { RequestReset, ResetPassword, Signin, Signout, VerifyEmail } from '.';
import { Paths } from 'app/config';

function Auth({user}) {
    return (
        <div className="container">
            <Switch>
                {<Route
                    path={Paths.absolute('userVerifyEmail', ':email')}
                    render={routerProps => <VerifyEmail user={user} {...routerProps}/>}
                />}
                {/**/!user && <Route
                    path={Paths.absolute('userRequestReset')}
                    render={routerProps => <RequestReset {...routerProps}/>}
                />}
                {/**/!user && <Route
                    path={Paths.absolute('userResetPassword')}
                    render={routerProps => <ResetPassword {...routerProps}/>}
                />}
                {/**/!user && <Route
                    path={Paths.absolute('userSignin')}
                    render={routerProps => <Signin {...routerProps}/>}
                />}
                {user && <Route
                    path={Paths.absolute('userSignout')}
                    render={routerProps => <Signout {...routerProps}/>}
                />}
                <Redirect to={Paths.absolute('root')}/>
            </Switch>
        </div>
    );
}

export default Auth;
