import React, { Component } from 'react';
import axios from 'app/lib/axios';
import { Paths } from 'app/config';
import { ContentMessage, Loading } from 'app/components/common';
import { Header, ProfileGrid } from '.';
import validate from 'app/lib/validate';

class Profiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            profiles: null
        };
    }
    componentDidMount() {
        this.getProfiles();
    }
    componentDidUpdate(prevProps) {
        if (this.props.match.params.category !== prevProps.match.params.category) {
            this.refreshProfiles();
        }
    }
    getProfiles = () => {
        const category = this.props.match.params.category || '';
        axios.get(Paths.service('profiles', category)).then(r => {
            console.log('Profiles :: getProfiles ::', r.data);
            this.setState({profiles: r.data, loading: false});
        }).catch(error => {
            validate.dataErrors('Profiles :: getProfiles [Error] ::', error);
            this.setState({loading: false});
        });
    }
    refreshProfiles = () => {
        this.setState({loading: true, profiles: null}, this.getProfiles);
    }
    render() {
        const { loading, profiles } = this.state;
        return (
            <div className="profile-catalog container">
                {loading && <Loading padded/>}
                {!loading && profiles && <>
                    <Header {...this.props}/>
                    <ProfileGrid profiles={profiles}/>
                </>}
                {!loading && !profiles &&
                    <ContentMessage message="No profiles found." padded/>
                }
            </div>
        );
    }
}

export default Profiles;
