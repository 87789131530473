import React from 'react';
import { IconStorefront } from 'app/assets/icons';
import { ExternalLink } from 'app/components/common';
import categories from 'app/data/licenseCategories';

function Body({data}) {
    return (
        <div className="body text-small">
            {data.license_category &&
                <p className="category has-icon has-icon-small">
                    <span className="icon"><IconStorefront/></span>
                    {categories[data.license_category].label}
                </p>
            }
            {data.description &&
                <p className="description readable-text">{data.description}</p>
            }
            {data.website_url &&
                <ExternalLink className="website-url" href={data.website_url}/>
            }
        </div>
    );
}

export default Body;
