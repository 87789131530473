import { Paths as _Paths } from 'app/lib';

const Paths = new _Paths();

Paths.routePaths = {
    ...Paths.routePaths,

    access:                                 '/access',
    accessRequest:                          '/access/request',
    accessSetPassword:              ids =>  `/access/password/${ids[0]}`,
    accessExpired:                  ids =>  `/access/expired/${ids[0]}`,

    auth:                                   '/user',
    userSignin:                             '/user/signin',
    userSignout:                            '/user/signout',
    userRequestReset:                       '/user/reset',
    userResetPassword:                      '/user/password',
    userVerifyEmail:                ids =>  `/user/email/${ids[0]}`,

    messages:                       ids =>  `/messages/${ids[0]}`,

    product:                                '/product',
    productAdd:                             '/product/add',
    productEdit:                    ids =>  `/product/edit/${ids[0]}`,
    productHome:                    ids =>  `/product/${ids[0]}`,
    products:                       ids =>  `/products/${ids[0]}`,

    profile:                                '/profile',
    profileEdit:                            '/profile/edit',
    profileHome:                    ids =>  `/profile/${ids[0]}`,
    profiles:                       ids =>  `/profiles/${ids[0]}`,

    serviceRequests:                ids =>  `/service-requests/${ids[0]}`,
    serviceRequestAdd:                      '/service-requests/add',
    serviceRequestEdit:             ids =>  `/service-requests/edit/${ids[0]}`,

    search:                                 '/search',
    searchProducts:                 ids =>  `/search/products/${ids[0]}`,
    searchProfiles:                 ids =>  `/search/profiles/${ids[0]}`,

    settings:                               '/settings',
    settingAccount:                         '/settings/account',
    settingLicense:                         '/settings/license',
    settingPassword:                        '/settings/password',
    settingBadges:                          '/settings/badges',

    policies:                               '/policies',
    policyDisclaimer:                       '/policies/disclaimer',
    policyPrivacy:                          '/policies/privacy',
    policyTerms:                            '/policies/terms',

    logout:                                 '/logout',
    logoutRequests:                         '/logout/requests',
    logoutLicenses:                         '/logout/licenses',
    logoutLookup:                           '/logout/lookup',
    logoutUpdateEmailLists:                 '/logout/update-email-lists',

    // storage paths
    licenseDocument:                        'licenses',
    productImage:                   ids =>  `users/${ids[0]}/products/images`,
    profileImage:                   ids =>  `users/${ids[0]}/profile/images`
};

Paths.servicePaths = {
    ...Paths.servicePaths,

    // public routes (no authentication required)
    requestAccess:                          '/pub/access/request',
    accessExpired:                  ids =>  `/pub/access/expired/${ids[0]}`,
    userRequestReset:               ids =>  `/pub/user/reset/${ids[0]}`,
    userVerifyEmail:                ids =>  `/pub/user/verify/${ids[0]}`,
    userProfile:                    ids =>  `/pub/user/profile/${ids[0]}`,

    // routes that require authentication
    accessRequests:                         '/logout/access/requests',
    accessRequest:                          '/logout/access/request',
    userLookup:                     ids =>  `/logout/user/lookup/${ids[0]}`,
    updateEmailLists:                       '/logout/updateEmailLists',

    account:                                '/account',
    license:                                '/license',
    search:                         ids =>  `/search/${ids[0]}`,

    product:                        ids =>  `/product/${ids[0]}`,
    products:                       ids =>  `/products/${ids[0]}`,
    userProducts:                   ids =>  `/products/user/${ids[0]}`,

    profile:                        ids =>  `/profile/${ids[0]}`,
    profiles:                       ids =>  `/profiles/${ids[0]}`,

    serviceRequests:                ids =>  `/service-requests/${ids[0]}`,

    userThreads:                            '/messages/threads',
    threadMessages:                 ids =>  `/messages/thread/${ids[0]}`
};

export default Paths;
