import React from 'react';
import { CharacterLimit, FieldGroup, FieldGroupSelect } from 'app/components/common';
import { fieldErrors } from 'app/lib/forms';
import categoryMap, { productCategories } from 'app/data/product/categoryMap';

function Fields(props) {
    const { disabled, errors, formData, handleChange } = props;
    return (
        <div className="form-group">
            <FieldGroup
                label="Title"
                errors={fieldErrors('title', errors)}>
                <input type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    disabled={disabled}
                    autoFocus required/>
            </FieldGroup>
            <FieldGroup
                label="Description"
                errors={fieldErrors('description', errors)}
                hint={<CharacterLimit value={formData.description} limit={1000}/>}>
                <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldGroup>
            <FieldGroupSelect
                {...props}
                label="Product Type"
                name="category"
                optionMap={productCategories()}
                handleChange={e => {
                    // remove existing attributes when category changes
                    const _formData = {...formData};
                    Object.keys(_formData).forEach(key => {
                        if (key.startsWith('attribute_')) delete _formData[key];
                    });
                    props.liftState({formData: _formData}, () => handleChange(e));
                }}
            />
            {formData.category === 'edible' &&
                <FieldGroupSelect
                    {...props}
                    label={categoryMap.edible.attributes.edible_type.label}
                    name="attribute_edible_type"
                    optionMap={categoryMap.edible.attributes.edible_type.map}
                />
            }
            {formData.category === 'extract' &&
                <FieldGroupSelect
                    {...props}
                    label={categoryMap.extract.attributes.extract_type.label}
                    name="attribute_extract_type"
                    optionMap={categoryMap.extract.attributes.extract_type.map}
                />
            }
            {formData.category === 'flower' && <>
                <FieldGroupSelect
                    {...props}
                    label={categoryMap.flower.attributes.strain_descriptor.label}
                    name="attribute_strain_descriptor"
                    optionMap={categoryMap.flower.attributes.strain_descriptor.map}
                />
                <FieldGroupSelect
                    {...props}
                    label={categoryMap.flower.attributes.grow_environment.label}
                    name="attribute_grow_environment"
                    optionMap={categoryMap.flower.attributes.grow_environment.map}
                />
            </>}
            {formData.category === 'nursery' &&
                <FieldGroupSelect
                    {...props}
                    label={categoryMap.nursery.attributes.growth_type.label}
                    name="attribute_growth_type"
                    optionMap={categoryMap.nursery.attributes.growth_type.map}
                />
            }
            {formData.category === 'pill' &&
                <FieldGroupSelect
                    {...props}
                    label={categoryMap.pill.attributes.pill_form.label}
                    name="attribute_pill_form"
                    optionMap={categoryMap.pill.attributes.pill_form.map}
                />
            }
            {formData.category === 'terpene' &&
                <FieldGroupSelect
                    {...props}
                    label={categoryMap.terpene.attributes.aroma.label}
                    name="attribute_aroma"
                    optionMap={categoryMap.terpene.attributes.aroma.map}
                />
            }
            {formData.category === 'tincture' &&
                <FieldGroupSelect
                    {...props}
                    label={categoryMap.tincture.attributes.tincture_type.label}
                    name="attribute_tincture_type"
                    optionMap={categoryMap.tincture.attributes.tincture_type.map}
                />
            }
        </div>
    );
}

export default Fields;
