import React from 'react';
import { NavLink } from 'react-router-dom';
import { Paths } from 'app/config';
import { IconBadge, IconCertificate, IconCog, IconUserCog, IconUnlock } from 'app/assets/icons';

function Nav() {
    return (
        <div className="settings-nav">
            <div className="side-nav card">
                <div className="nav-section">
                    <h3 className="header has-icon">
                        <span className="icon"><IconCog/></span>
                        Settings
                    </h3>
                    <NavLink
                        exact to={Paths.route('settingAccount')}
                        className="nav-link"
                        activeClassName="active">
                        <span className="icon flip-h"><IconUserCog/></span>
                        <span className="label">My Account</span>
                    </NavLink>
                    <NavLink
                        exact to={Paths.route('settingLicense')}
                        className="nav-link license-link"
                        activeClassName="active">
                        <span className="icon"><IconCertificate/></span>
                        <span className="label">Cannabis License</span>
                    </NavLink>
                    <NavLink
                        exact to={Paths.route('settingPassword')}
                        className="nav-link"
                        activeClassName="active">
                        <span className="icon"><IconUnlock/></span>
                        <span className="label">Password</span>
                    </NavLink>
                    <NavLink
                        exact to={Paths.route('settingBadges')}
                        className="nav-link"
                        activeClassName="active">
                        <span className="icon"><IconBadge/></span>
                        <span className="label">Badges</span>
                    </NavLink>
                </div>
            </div>
        </div>
    );
}

export default Nav;
