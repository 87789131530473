import React from 'react';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';

function Indicator({status}) {
    return (
        <Tippy content={status} className="capitalize">
            <span className={classNames('indicator-dot', {
                'has-success-bg': status === 'approved',
                'has-warning-bg': status === 'pending',
                'has-danger-bg': status === 'denied'
            })}/>
        </Tippy>
    );
}

export default Indicator;
