import React, { useState } from 'react';
import { License } from 'app/components/Settings';
import { LicenseFile } from './AccessRequests';

function Licenses(props) {
    const [license, setLicense] = useState(null);
    return (
        <div className="licenses settings-section">
            <License
                {...props}
                disabledFields={[]}
                getLicenseCallback={setLicense}
            />
            {license &&
                <div className="license-file">
                    <LicenseFile fileName={license.file}/>
                </div>
            }
        </div>
    );
}

export default Licenses;
