import React from 'react';
import { ContactEmail } from 'app/components/common';
import { ProductNav, ProfileNav, ServiceNav } from '.';

function SideNav() {
    return (
        <div className="side-nav">
            <div className="bg-shim"></div>
            <div className="nav-section">
                <ServiceNav/>
                <ProductNav/>
                <ProfileNav/>
            </div>
            <div className="nav-footer text-center text-small">
                <span className="label">Support</span>
                <ContactEmail className="incognito"/>
            </div>
        </div>
    );
}

export default SideNav;
