import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Account, Badges, License, Nav, Password } from '.';
import { Paths } from 'app/config';

function Settings({user}) {
    return (
        <div className="settings container">
            <div className="cols">
                    <Nav/>
                <div className="settings-section col">
                    <Switch>
                        <Route
                            path={Paths.absolute('settingAccount')}
                            render={routerProps => <Account user={user} {...routerProps}/>}
                        />
                        <Route
                            path={Paths.absolute('settingLicense')}
                            render={routerProps => <License user={user} {...routerProps}/>}
                        />
                        <Route
                            path={Paths.absolute('settingPassword')}
                            render={routerProps => <Password user={user} {...routerProps}/>}
                        />
                        <Route
                            path={Paths.absolute('settingBadges')}
                            render={routerProps => <Badges user={user} {...routerProps}/>}
                        />
                        <Redirect to={Paths.absolute('settingAccount')}/>
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default Settings;
