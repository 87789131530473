import React from 'react';
import { ContentMessage, Loading } from 'app/components/common';
import { ProductGrid } from 'app/components/Products';
import { ProfileGrid } from 'app/components/Profiles';
import { Header } from '.';

const components = {
    products: ProductGrid,
    profiles: ProfileGrid
};

function Results(props) {
    const { data, errors, loading, source } = props;
    return (
        <div className="results">
            <Header {...props}/>
            {loading && <Loading padded/>}
            {!loading && data &&
                React.createElement(components[source], {[source]: data}, null)
            }
            {!loading && !data &&
                <ContentMessage message={`No ${source} found.`} padded/>
            }
            {errors &&
                <ContentMessage message="Something went wrong!" error padded/>
            }
        </div>
    );
}

export default Results;
