import React, { Component } from 'react';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { IconChatBubble } from 'app/assets/icons';
import { ContentMessage, Loading } from 'app/components/common';
import { Thread } from '..';
import { Row } from '.';
import { initFirestore } from 'app/lib/firebaseMethods';

const initialState = {
    loading: true,
    threads: [],
    activeThread: null
};

class Threads extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.threadObject = props.location.state?.thread && props.location.state.thread;
    }
    componentDidUpdate(prevProps) {
        if (this.props.profile && !prevProps.profile) {
            this.subscribeToMessageThreads();
        }
        // handle clicking of message icon while in messages
        else if (!this.props.match.params.id && prevProps.match.params.id && !this.state.loading) {
            this.setState(initialState, this.getThreads);
        }
    }
    componentWillUnmount() {
        if (typeof this.unsubscribe === 'function') this.unsubscribe();
    }
    liftState = (state, callback) => {
        this.setState(state, callback);
    }
    subscribeToMessageThreads = () => {
        const db = initFirestore();
        const query = ['uids', 'array-contains', this.props.user.user_id];
        this.unsubscribe = db.collection('messageThreads').where(...query).onSnapshot(r => {
            this.getThreads();
        });
    }
    getThreads = () => {
        axios.get(Paths.service('userThreads')).then(r => {
            console.log('Messages/Threads :: getThreads ::', r.data);
            const threads = r.data.sort((a, b) => b.updated._seconds - a.updated._seconds);
            if (threads.length || this.threadObject) this.setActiveThread(threads);
            else this.setState({loading: false});
        }).catch(error => {
            validate.dataErrors('Messages/Threads :: getThreads [Error] ::', error);
            this.setState({loading: false});
        });
    }
    setActiveThread = threads => {
        const { match } = this.props;
        let activeThread = null;
        // find a thread matching thread id (when user switches threads)
        if (match.params.id) activeThread = threads.find(t => t.id === match.params.id);
        // find a thread matching uids (when user comes from profile, product, etc)
        if (!activeThread && this.threadObject) {
            activeThread = threads.find(t =>
                t.uids.sort().join('') === this.threadObject.uids.sort().join('')
            );
        }
        // create new thread
        if (!activeThread && this.threadObject) {
            this.createNewThread(threads);
        }
        // activeThread found -OR- default to most recent thread
        else {
            activeThread = activeThread || threads[0];
            this.pushActiveThread(activeThread.id);
            this.setState({threads, activeThread, loading: false});
        }
    }
    createNewThread = threads => {
        const newThreads = [this.threadObject, ...threads];
        this.pushActiveThread(newThreads[0].id);
        this.setState({threads: newThreads, activeThread: newThreads[0], loading: false});
    }
    pushActiveThread = activeThreadId => {
        this.props.history.push(Paths.route('messages', activeThreadId));
    }
    render() {
        const { activeThread, loading, threads } = this.state;
        if (loading) {
            return <Loading padded/>;
        }
        if (!loading && activeThread) {
            return (
                <div className="cols">
                    <div className="message-threads col">
                        <div className="card">
                            <div className="header has-icon has-icon-small">
                                <span className="icon"><IconChatBubble/></span>
                                Messages
                            </div>
                            <div className="feed">
                                {threads.map(t =>
                                    <Row {...this.props}
                                        key={t.id}
                                        thread={t}
                                        liftState={this.liftState}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <Thread
                        {...this.props}
                        activeThread={activeThread}
                        profile={this.props.profile}
                    />
                </div>
            );
        }
        if (!loading && !activeThread) {
            return <ContentMessage message="No messages found." padded/>;
        }
    }
}

export default Threads;
