import React from 'react';
import { timeDistance } from 'app/lib/helpers';

function TimeDistance({timestamp}) {
    return (
        <span className="time-distance">
            {timeDistance(timestamp)}
        </span>
    );
}

export default TimeDistance;
