const plural = n => n === 1 ? '' : 's';

module.exports = {

    authCredentialsInvalid:     'email or password is incorrect',
    authFailedAttempts:         'temporarily disabled because of too many failed attempts',
    authIncorrectPassword:      'incorrect password',

    dateFormat:                 format => `must be in ${format} format`,
    dateInFuture:               'must be a future date',
    dateInPast:                 'must be a past date',

    docNotFound:                'document not found',

    emailInvalid:               'email address is invalid',
    emailNotFound:              'email address not found',
    emailUnavailable:           'email address is unavailable',

    fileRequired:               'a file attachment is required',

    genericError:               'something went wrong',

    lengthMax:                  length => `length may not exceed ${length} character${plural(length)}`,
    lengthMin:                  length => `length must be at least ${length} character${plural(length)}`,

    required:                   'required',

    urlInvalid:                 'URL is invalid',
};
