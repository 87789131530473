import React, { Component } from 'react';
import axios from 'app/lib/axios';
import { Paths } from 'app/config';
import { ContentMessage, Loading } from 'app/components/common';
import { Body, Header, ImageGroup, SideBar } from '.';
import validate from 'app/lib/validate';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null
        };
    }
    componentDidMount() {
        this.getData();
    }
    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.refreshData();
        }
    }
    getData = () => {
        axios.get(Paths.service('product', this.props.match.params.id)).then(r => {
            console.log('Product/Home :: getData ::', r.data);
            this.setState({data: r.data, loading: false});
        }).catch(error => {
            validate.dataErrors('Product/Home :: getData [Error] ::', error);
            this.setState({loading: false});
        });
    }
    refreshData = () => {
        this.setState({data: null, loading: true}, this.getData);
    }
    render() {
        const { loading, data } = this.state;
        return (
            <div className="product-home">
                {loading && <Loading padded/>}
                {!loading && data &&
                    <div className="cols">
                        <ImageGroup data={data}/>
                        <div className="details">
                            <Header data={data}/>
                            <Body data={data}/>
                        </div>
                        <SideBar {...this.props} data={data}/>
                    </div>
                }
                {!loading && !data &&
                    <ContentMessage message="Product not found." padded/>
                }
            </div>
        );
    }
}

export default Home;
