import React, { Component } from 'react';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { Threads } from '.';
import { getResizedImageURL } from 'app/lib/firebaseMethods';

class Messages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null
        }
    }
    componentDidMount() {
        this.getProfile();
    }
    getProfile = () => {
        axios.get(Paths.service('profile', this.props.user.user_id)).then(r => {
            console.log('Messages :: getProfile ::', r.data);
            this.setState({profile: r.data}, this.getProfileImage);
        }).catch(error => {
            validate.dataErrors('Messages :: getProfile [Error] ::', error);
        });
    }
    getProfileImage = async () => {
        const { profile } = this.state;
        if (!profile.image) return false;
        const bucket = Paths.storage('profileImage', profile.uid);
        const imageDownloadURL = await getResizedImageURL(bucket, profile.image, 300);
        this.setState({profile: {...profile, imageDownloadURL}});
    }
    render() {
        return (
            <div className="messages container">
                <Threads
                    {...this.props}
                    profile={this.state.profile}
                />
            </div>
        );
    }
}

export default Messages;
