import React from 'react';
import classNames from 'classnames';

function FieldGroup({children, errors, hint, label}) {
    return (
        <div className={classNames('field-group', {'has-error': errors})}>
            {label && <label>{label}</label>}
            {children}
            {hint && <div className="field-hint">{hint}</div>}
            {Array.isArray(errors) && !!errors.length &&
                <ul className="field-errors">
                    {errors.map((error, i) =>
                        <li key={i} className="error">{error.message}</li>
                    )}
                </ul>
            }
        </div>
    );
}

export default FieldGroup;
