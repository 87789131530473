import React from 'react';
import { FileDrop } from 'app/components/common';
import { Image } from '.';

function ImageGroup(props) {
    const { images, liftState } = props;
    return (
        <div className="images">
            <div className="field-group">
                <label>Images</label>
            </div>
            {[1,2,3,4].map(i =>
                <div className="field-group" key={i}>
                    {images[i-1]
                        ? <Image
                            bucket={props.bucket}
                            fileName={images[i-1]}
                            markImageForDeletion={props.markImageForDeletion}
                        />
                        : <FileDrop
                            files={props[`files_${i}`]}
                            onDrop={files => liftState({[`files_${i}`]: files})}
                            onRemove={() => liftState({[`files_${i}`]: []})}
                            disabled={props.disabled}
                            label="Drop or click"
                        />
                    }
                </div>
            )}
        </div>
    );
}

export default ImageGroup;
