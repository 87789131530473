import React, { Component } from 'react';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { ContentMessage, Loading } from 'app/components/common';
import { Products } from '..';
import { Body, Header, ProfileImage, ServiceProviders } from '.';
import { getResizedImageMetadata } from 'app/lib/firebaseMethods';
import { canProductWrite } from 'app/lib/perms';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            profile: null,
            imageMetadata: null
        };
    }
    componentDidMount() {
        this.getProfile();
    }
    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.refreshProfile();
        }
    }
    getProfile = () => {
        axios.get(Paths.service('profile', this.props.match.params.id)).then(r => {
            console.log('Profile/Home :: getProfile ::', r.data);
            this.setState({profile: r.data}, () => {
                if (r.data.image) this.getImageMetadata(r.data);
                else this.setState({loading: false});
            });
        }).catch(error => {
            validate.dataErrors('Profile/Home :: getProfile [Error] ::', error);
            this.setState({loading: false});
        });
    }
    getImageMetadata = profile => {
        const bucket = Paths.storage('profileImage', profile.uid);
        getResizedImageMetadata(bucket, profile.image, 600).then(r => {
            console.log('Profile/Home :: getImageMetadata ::', r);
            this.setState({imageMetadata: r.customMetadata, loading: false});
        });
    }
    refreshProfile = () => {
        this.setState({profile: null, loading: true}, this.getProfile);
    }
    render() {
        const { user } = this.props;
        const { loading, profile, imageMetadata } = this.state;
        return (
            <div className="profile-home">
                {loading && <Loading padded/>}
                {!loading && profile && <>
                    <div className="profile-content cols">
                        <div className="profile-image">
                            <ProfileImage data={profile} imageMetadata={imageMetadata}/>
                        </div>
                        <div className="details col">
                            <Header {...this.props} data={profile}/>
                            <Body data={profile}/>
                        </div>
                    </div>
                    <div className="content-spacer"></div>
                    {canProductWrite(profile.license_category) &&
                        <Products {...this.props} profile={profile}/>
                    }
                    {!canProductWrite(profile.license_category) && profile.uid === user.user_id &&
                        <ServiceProviders/>
                    }
                </>}
                {!loading && !profile &&
                    <ContentMessage message="Profile not found." padded/>
                }
            </div>
        );
    }
}

export default Home;
