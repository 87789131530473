import axios from 'axios';
import store from 'redux/store';
import { getIdTokenResult } from 'app/lib/firebaseMethods';

axios.interceptors.request.use(async config => {
    const { bearerExpiry, userContext } = store.getState().config;

    // refresh expired bearer token
    if (bearerExpiry && bearerExpiry - Date.now() < 0) {
        await getIdTokenResult();
    }
    // set bearer token
    const { bearer } = store.getState().config;
    if (bearer) config.headers['Authorization'] = `Bearer ${bearer}`;

    // set user context
    if (userContext) config.headers['UserContext'] = userContext;

    // environment header
    if (process.env.NODE_ENV === 'development') {
        config.headers['Environment'] = 'development';
    }
    return config;
});

export default axios;
