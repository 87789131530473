import React from 'react';
import { Paths } from 'app/config';
import { IconCannabisLeaf } from 'app/assets/icons';
import { hasPermissions } from 'app/lib/perms';
const categories = require('app/data/product/categoryMap').productCategories();

function Header({history, match, user}) {
    return (
        <div className="common-header cols">
            <h2 className="col has-icon">
                <span className="icon has-success"><IconCannabisLeaf/></span>
                Products
                {match.params.category &&
                    <span className="badge badge-info">
                        {categories[match.params.category]}
                    </span>
                }
            </h2>
            {hasPermissions(user, ['product.write']) &&
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => history.push(Paths.route('productAdd'))}>
                    Add Product
                </button>
            }
        </div>
    );
}

export default Header;
