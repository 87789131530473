import React from 'react';
import classNames from 'classnames';

function CharacterLimit({limit, value}) {
    return (
        <div className={classNames('character-limit', {'has-error': value.length > limit})}>
            {limit - value.length} characters remaining
        </div>
    );
}

export default CharacterLimit;
