import React, { useState, useEffect } from 'react';
import { Paths } from 'app/config';
import { ExternalLink } from 'app/components/common';
import { getFileURL } from 'app/lib/firebaseMethods';

function LicenseFile({fileName}) {
    const [src, setSrc] = useState(null);
    const label = 'View License File';

    useEffect(() => {
        getFileURL(Paths.storage('licenseDocument'), fileName).then(s => setSrc(s));
    }, [fileName]);

    return src ? (
        <ExternalLink href={src}>{label}</ExternalLink>
    ) : <span className="text-muted">{label}</span>;
}

export default LicenseFile;
