import React from 'react';
import { Paths } from 'app/config';
import { messagePath } from 'app/components/Messages';

function Header({data, history, user}) {
    return (
        <div className="common-header cols">
            <h1 className="col">{data.business_name}</h1>
            {data.uid === user.user_id ?
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => history.push(Paths.route('profileEdit'))}>
                    Edit Profile
                </button>
                :
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => history.push(messagePath(user.user_id, data))}>
                    Send Message
                </button>
            }
        </div>
    );
}

export default Header;
