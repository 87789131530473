import React from 'react';
import { NavLink } from 'react-router-dom';
import { Paths } from 'app/config';
import serviceCategories from 'app/data/serviceCategories';

function Tabs({match}) {
    return (
        <div className="nav-tabs">
            <NavLink
                to={Paths.route('serviceRequests')}
                className="nav-tab"
                activeClassName="active"
                isActive={() => !match.params.category}>
                <span className="label">All</span>
            </NavLink>
            {Object.keys(serviceCategories).map(key =>
                <NavLink
                    key={key}
                    to={Paths.route('serviceRequests', key)}
                    className="nav-tab"
                    activeClassName="active">
                    <span className="label">
                        {serviceCategories[key]}
                    </span>
                </NavLink>
            )}
        </div>
    );
}

export default Tabs;
