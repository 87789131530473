import React from 'react';
import { NavLink } from 'react-router-dom';
import { Paths } from 'app/config';
import { IconCannabisLeaf } from 'app/assets/icons';
const categories = require('app/data/product/categoryMap').productCategories();

function ProductNav() {
    return <>
        <NavLink
            exact to={Paths.route('products')}
            className="nav-link"
            activeClassName="active"
            isActive={(match, location) => location.pathname.startsWith('/product')}>
            <span className="icon"><IconCannabisLeaf/></span>
            <span className="label">Products</span>
        </NavLink>
        <div className="sub-nav">
            {Object.keys(categories).map(key =>
                <NavLink
                    key={key}
                    to={Paths.route('products', key)}
                    className="nav-link"
                    activeClassName="active">
                    <span className="label">
                        {categories[key]}
                    </span>
                </NavLink>
            )}
        </div>
    </>;
}

export default ProductNav;
