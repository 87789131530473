class Paths {
    constructor() {
        this.routePaths = {
            root: '/'
        };
        this.servicePaths = {
            service: '/api/v1'
        };
    }
    buildRoute(key, ids) {
        ids = Array.isArray(ids) ? ids : [ids || ''];
        const path = this.routePaths[key];
        return typeof path === 'function' ? path(ids) : path;
    }
    buildService(key, ids) {
        ids = Array.isArray(ids) ? ids : [ids || ''];
        ids = ids.map(id => encodeURIComponent(id));
        const path = this.servicePaths[key];
        return typeof path === 'function' ? path(ids) : path;
    }
    absolute(key, ids) {
        return this.buildRoute(key, ids);
    }
    route(key, ids, props = {}) {
        const to = {};
        to.pathname = this.buildRoute(key, ids);
        if ('search' in props) to.search = props.search || window.location.search;
        if ('hash' in props) to.hash = props.hash;
        if ('state' in props) to.state = props.state;
        return to;
    }
    service(key, ids) {
        return this.servicePaths.service + this.buildService(key, ids);
    }
    storage(key, ids) {
        let prefix = '';
        if (process.env.NODE_ENV === 'development') prefix = '_local/';
        return prefix + this.buildRoute(key, ids);
    }
}

export default Paths;
