import React from 'react';
import { Link } from 'react-router-dom';
import { Paths } from 'app/config';
import { ContentMessage } from 'app/components/common';
import { Image } from '.';
import categories from 'app/data/licenseCategories';

function ProfileGrid({profiles = []}) {
    return (
        <div className="profile-grid">
            {!!profiles.length &&
                <div className="profiles">
                    {profiles.map(p =>
                        <div className="profile card" key={p.id}>
                            <Image profile={p}/>
                            <div className="metadata">
                                <h3 className="title">
                                    <Link to={Paths.route('profileHome', p.uid)} className="incognito">
                                        {p.business_name}
                                    </Link>
                                </h3>
                                <div className="category">
                                    <span className="badge badge-info">
                                        {categories[p.license_category].label}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }
            {!profiles.length &&
                <ContentMessage message="No profiles found." padded/>
            }
        </div>
    );
}

export default ProfileGrid;
