import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { withToastManager } from 'react-toast-notifications';
import { toast } from 'app/lib/helpers';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { IconCertificate } from 'app/assets/icons';
import { CommonForm } from 'app/components/common';
import { FormFooter } from '..';
import { Fields } from '.';
import { storeFile } from 'app/lib/firebaseMethods';

class Form extends CommonForm {
    state = {
        ...this.state,
        formData: {
            number:             this.props.license.number || '',
            type:               this.props.license.type || '',
            issue_date:         this.props.license.issue_date || '',
            expiration_date:    this.props.license.expiration_date || '',
            file:               this.props.license.file || '',
            business_name:      this.props.license.business_name || '',
            address1:           this.props.license.address1 || '',
            address2:           this.props.license.address2 || '',
            city:               this.props.license.city || '',
            state:              this.props.license.state || '',
            zip:                this.props.license.zip || ''
        },
        files: []
    };
    validators = () => [
        ...validate.commonText('number',            this.state.formData.number),
        ...validate.commonText('type',              this.state.formData.type),
        ...validate.dateFormat('issue_date',        this.state.formData.issue_date),
        ...validate.dateInPast('issue_date',        this.state.formData.issue_date),
        ...validate.dateFormat('expiration_date',   this.state.formData.expiration_date),
        ...validate.dateInFuture('expiration_date', this.state.formData.expiration_date),
        ...validate.fileExists('file',              this.state.files[0] ? this.state.files[0].name : this.state.formData.file),
        ...validate.commonText('business_name',     this.state.formData.business_name),
        ...validate.commonText('address1',          this.state.formData.address1),
        ...validate.commonText('address2',          this.state.formData.address2, {required: false}),
        ...validate.commonText('city',              this.state.formData.city),
        ...validate.commonText('state',             this.state.formData.state),
        ...validate.commonText('zip',               this.state.formData.zip)
    ];
    submitForm = async () => {
        const { user } = this.props;
        const { files } = this.state;
        const payload = {...this.state.formData};
        // upload new license document
        if (files.length) {
            payload.file = `${new Date().getTime()}_${user.user_id}_${uuidv4()}_license_file.${files[0].name.split('.').pop()}`;
            await storeFile(Paths.storage('licenseDocument'), payload.file, files[0]);
        }
        // submit form
        axios.put(Paths.service('license'), payload).then(r => {
            console.log('Settings/License/Form :: submitForm ::', r.data);
            this.props.toastManager.add(...toast("Success! Your license has been updated."));
            this.setState({disabled: false}, this.props.successCallback && this.props.successCallback);
        }).catch(error => {
            const errors = validate.dataErrors('Settings/License/Form :: submitForm [Error] ::', error);
            this.setState({disabled: false, errors});
        });
    }
    render() {
        const { disabled, errors, files, formData } = this.state;
        return (
            <div className="card">
                <form onSubmit={this.handleSubmit}>
                    <h3 className="form-title has-icon">
                        <span className="icon">
                            <IconCertificate/>
                        </span>
                        Cannabis License
                    </h3>
                    <Fields
                        disabled={disabled}
                        disabledFields={this.props.disabledFields || ['number', 'type']}
                        errors={errors}
                        formData={formData}
                        handleChange={this.handleChange}
                        files={files}
                        license={this.props.license}
                        liftState={this.liftState}
                    />
                    <FormFooter
                        disabled={disabled}
                        errors={errors}
                    />
                </form>
            </div>
        );
    }
}

export default withToastManager(Form);
