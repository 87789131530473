import React, { Component } from 'react';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { ContentMessage, Loading } from 'app/components/common';
import { Form } from 'app/components/Settings/License';

class LicenseGate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            license: null,
            loading: true
        };
    }
    componentDidMount() {
        this.getLicense();
    }
    getLicense = () => {
        axios.get(Paths.service('license')).then(r => {
            console.log('Gateway/LicenseGate :: getLicense ::', r.data);
            if (r.data.address1) this.props.removeGate('licenseGate');
            else this.setState({license: r.data, loading: false});
        }).catch(error => {
            validate.dataErrors('Gateway/LicenseGate :: getLicense [Error] ::', error);
        });
    }
    render() {
        const { license, loading } = this.state;
        return loading ? <Loading padded/> : (
            <div className="license-gate settings-section container">
                <ContentMessage message="Complete your cannabis license details to continue..."/>
                <Form
                    {...this.props}
                    license={license}
                    successCallback={() => this.props.removeGate('licenseGate')}
                />
            </div>
        );
    }
}

export default LicenseGate;
