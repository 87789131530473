import React, { Component } from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import { toast } from 'app/lib/helpers';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { ContactEmail, Loading } from 'app/components/common';
import { Signout } from '.';
import { applyActionCode } from 'app/lib/firebaseMethods';

class VerifyEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            error: false,
            loading: true,
            verified: false
        };
    }
    componentDidMount() {
        const query = queryString.parse(window.location.search);
        if (query.code) this.verifyCode(query.code);
        else this.setState({loading: false});
    }
    verifyCode = code => {
        applyActionCode(code).then(() => {
            console.log('Auth/VerifyEmail :: verifyCode');
            this.setState({loading: false, verified: true});
        }).catch(error => {
            validate.dataErrors('Auth/VerifyEmail :: verifyCode [Error] ::', error);
            this.setState({error: true, loading: false});
        });
    }
    resendEmail = e => {
        e.preventDefault();
        this.setState({disabled: true});
        axios.get(Paths.service('userVerifyEmail', this.props.match.params.email)).then(r => {
            console.log('Auth/VerifyEmail :: resendEmail ::', r.data);
            const m = "Success! Verification email sent. Please check your email inbox.";
            this.props.toastManager.add(...toast(m));
            this.setState({disabled: false, error: false});
        }).catch(error => {
            validate.dataErrors('Auth/VerifyEmail :: resendEmail [Error] ::', error);
        });
    }
    render() {
        const { user } = this.props;
        const { disabled, error, loading, verified } = this.state;
        const resendText = "Resend Verification Email";
        return (
            <div className="verify-email">
                {user && <Signout/>}
                {loading && <Loading padded/>}
                {!loading && !verified &&
                    <div className="card message-card readable-text text-center">
                        <h2 className="section content-message">Verify Email Address</h2>
                        <h3 className="section">{this.props.match.params.email}</h3>
                        Please check your email inbox for a verification link.
                        <div className="sep-h"></div>
                        <div className="section text-small text-muted">
                            {error
                                ? <p className="text-red">Error: Your previous verification request may have expired.</p>
                                : <span>Didn't receive the email?{' '}</span>
                            }
                            {disabled ? resendText :
                                <a href="./" onClick={this.resendEmail} disabled="disabled">{resendText}</a>
                            }
                        </div>
                        <span className="text-small text-muted">
                            Need help? <ContactEmail/>
                        </span>
                    </div>
                }
                {!loading && verified &&
                    <div className="card message-card readable-text text-center">
                        <h2 className="common-header content-message">Success! Email Address Verified.</h2>
                        Please <SignInLink/> with your new email address.
                    </div>
                }
            </div>
        );
    }
}

const SignInLink = () => (
    <Link to={Paths.route('userSignin')}>sign in</Link>
);

export default withToastManager(VerifyEmail);
