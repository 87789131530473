import React, { Component } from 'react';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { ContentMessage, Loading } from 'app/components/common';
import { Composer, Feed, Header } from '.';
import { getResizedImageURL, initFirestore } from 'app/lib/firebaseMethods';

const initialState = {
    loading: true,
    activeThread: {partner: {}},
    messages: []
};

class Thread extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount() {
        this.initThread();
    }
    componentDidUpdate(prevProps) {
        // switching threads
        if (this.props.activeThread.partner.uid !== prevProps.activeThread.partner.uid) {
            this.setState(initialState, this.initThread);
        }
    }
    componentWillUnmount() {
        this.unsubscribeFromThread();
    }
    liftState = (state, callback) => {
        this.setState(state, callback);
    }
    initThread = () => {
        this.getPartnerImage();
        if (this.isNewThread()) this.setState({loading: false});
        else this.subscribeToThread(this.props.activeThread.id);
    }
    subscribeToThread = threadId => {
        this.unsubscribeFromThread();
        const db = initFirestore();
        this.unsubscribe = db.collection('messageThreads').doc(threadId).onSnapshot(doc => {
            console.log('Messages/Thread :: subscribeToThread');
            this.getMessages(threadId);
        });
    }
    unsubscribeFromThread = () => {
        if (typeof this.unsubscribe === 'function') this.unsubscribe();
    }
    getPartnerImage = async () => {
        const { activeThread } = this.props;
        if (activeThread.partner.image) {
            const bucket = Paths.storage('profileImage', activeThread.partner.uid);
            activeThread.partner.imageDownloadURL = await getResizedImageURL(bucket, activeThread.partner.image, 300);
        }
        this.setState({activeThread});
    }
    getMessages = threadId => {
        axios.get(Paths.service('threadMessages', threadId)).then(r => {
            console.log('Messages/Thread :: getMessages ::', r.data);
            this.setState({messages: r.data, loading: false});
        }).catch(error => {
            validate.dataErrors('Messages/Thread :: getMessages [Error] ::', error);
            this.setState({loading: false});
        });
    }
    pushMessage = message => {
        const messages = [...this.state.messages];
        messages.push(message);
        this.setState({messages});
    }
    isNewThread = () => {
        return this.props.activeThread.id === 'new';
    }
    render() {
        const { activeThread, loading, messages } = this.state;
        return (
            <div className="message-thread col">
                <div className="card">
                    {loading && <Loading padded/>}
                    {!loading && (!!messages.length || this.isNewThread()) && <>
                        <div className="thread-body">
                            <Header activeThread={activeThread}/>
                            <Feed
                                messages={messages}
                                activeThread={activeThread}
                                profile={this.props.profile}
                            />
                            <Composer
                                {...this.props}
                                user={this.props.user}
                                activeThread={activeThread}
                                pushMessage={this.pushMessage}
                                subscribeToThread={this.subscribeToThread}
                                liftState={this.liftState}
                            />
                        </div>
                    </>}
                    {!loading && !messages.length && !this.isNewThread() &&
                        <ContentMessage message="No messages found." padded/>
                    }
                </div>
            </div>
        );
    }
}

export default Thread;
