import React, { Component } from 'react';
import cloneDeep from 'lodash.clonedeep';
import axios from 'app/lib/axios';
import { Paths } from 'app/config';
import { ContentMessage, Loading } from 'app/components/common';
import { ExpandableItem, StatusFilter } from '.';
import validate from 'app/lib/validate';

class AccessRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            requests: [],
            filtered: []
        };
    }
    componentDidMount() {
        this.getAccessRequests();
    }
    getAccessRequests = () => {
        axios.get(Paths.service('accessRequests')).then(r => {
            console.log('Logout/AccessRequests :: getAccessRequests ::', r.data);
            this.setState({requests: r.data, filtered: r.data, loading: false});
        }).catch(error => {
            validate.dataErrors('Logout/AccessRequests :: getAccessRequests [Error] ::', error);
        });
    }
    refreshAccessRequests = () => {
        this.setState({requests: [], loading: true}, this.getAccessRequests);
    }
    filterAccessRequests = filter => {
        if (filter === 'all') return this.setState({filtered: this.state.requests});
        const requests = cloneDeep(this.state.requests);
        this.setState({filtered: requests.filter(r => r.status === filter)});
    }
    render() {
        const { filtered, loading } = this.state;
        return (
            <div className="access-requests">
                <div className="common-header cols">
                    <h2 className="col">Access Requests</h2>
                    <StatusFilter
                        filterAccessRequests={this.filterAccessRequests}
                        disabled={loading}
                    />
                </div>
                {loading && <Loading padded/>}
                {!loading && !!filtered.length &&
                    <ul className="list-expandable">
                        {filtered.map(request =>
                            <ExpandableItem
                                key={request.id}
                                request={request}
                                refreshAccessRequests={this.refreshAccessRequests}
                            />
                        )}
                    </ul>
                }
                {!loading && !filtered.length && <ContentMessage padded/>}
            </div>
        );
    }
}

export default AccessRequests;
