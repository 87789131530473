import React, { Component } from 'react';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { ContentMessage, Loading } from 'app/components/common';
import { Banner, Header, ProductGrid } from '.';
import { hasPermissions } from 'app/lib/perms';

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            products: null
        };
    }
    componentDidMount() {
        this.getProducts();
    }
    componentDidUpdate(prevProps) {
        if (this.props.match.params.category !== prevProps.match.params.category) {
            this.refreshProducts();
        }
    }
    getProducts = () => {
        const category = this.props.match.params.category || '';
        axios.get(Paths.service('products', category)).then(r => {
            console.log('Products :: getProducts ::', r.data);
            this.setState({products: r.data, loading: false});
        }).catch(error => {
            validate.dataErrors('Products :: getProducts [Error] ::', error);
            this.setState({loading: false});
        });
    }
    refreshProducts = () => {
        this.setState({loading: true, products: null}, this.getProducts);
    }
    render() {
        const { loading, products } = this.state;
        return (
            <div className="product-catalog container">
                {loading && <Loading padded/>}
                {!loading && products && <>
                    <Header {...this.props}/>
                    {hasPermissions(this.props.user, ['product.write']) &&
                        <Banner {...this.props}/>
                    }
                    <ProductGrid products={products}/>
                </>}
                {!loading && !products &&
                    <ContentMessage message="No products found." padded/>
                }
            </div>
        );
    }
}

export default Products;
