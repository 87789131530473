import React from 'react';
import { IconStorefront } from 'app/assets/icons';

function ServiceProviders() {
    return (
        <div className="card feature-card message-card readable-text">
            <h3 className="heading has-icon">
                <span className="icon"><IconStorefront/></span>
                New features for service providers coming soon!
            </h3>
            <span className="text-small line-height">
                In the mean time please fill out your profile description so
                operators can learn more about your business and the regions
                you serve.
            </span>
        </div>
    );
}

export default ServiceProviders;
