import { fromUnixTime, formatDistance } from 'date-fns';

// time distance (e.g. 10 days ago)
export function timeDistance(timestamp) {
    return formatDistance(fromUnixTime(timestamp), Date.now(), {includeSeconds: true});
}

// react toast notification params
export function toast(message, arg2) {
    const appearance = typeof arg2 === 'string' ? arg2 : 'success';
    const props = typeof arg2 === 'object' ? arg2 : {};
    return [message, {
        appearance,
        autoDismiss: true,
        autoDismissTimeout: 3500,
        placement: 'top-center',
        ...props
    }];
}

// cleanly truncate long text
export function longTrunc(str, chars = 80, append = '...') {
    if (str.length <= chars) return str;
    // characters to remove
    const exclusions = [' ', '.', ',', ';', '!', '?'];
    // truncate string
    str = str.substring(0, chars);
    // remove characters from end of string
    while (exclusions.includes(str.charAt(str.length - 1))) {
        str = str.substring(0, str.length - 1);
    }
    // remove last word
    let arr = str.split(' ');
    if (arr.length === 1) return arr[0];
    str = arr.splice(0, arr.length - 1).join(' ');
    // again, remove characters from end of string
    while (exclusions.includes(str.charAt(str.length - 1))) {
        str = str.substring(0, str.length - 1);
    }
    return str + append;
}
