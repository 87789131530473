import React, { useState } from 'react';
import Select, { selectProps } from 'app/components/common/Select';

const filters = {
    all: 'All',
    pending: 'Pending',
    approved: 'Approved',
    denied: 'Denied'
};

function StatusFilter({disabled, filterAccessRequests}) {
    const [filter, setFilter] = useState('all');
    const options = Object.keys(filters).map(f => ({label: filters[f], value: f}));
    function handleChange({value}) {
        setFilter(value);
        filterAccessRequests(value);
    }
    return (
        <div className="status-filter cols">
            <label className="col text-small">Status</label>
            <div className="field-group col">
                <Select
                    {...selectProps}
                    options={options}
                    value={options.find(o => o.value === filter)}
                    onChange={handleChange}
                    isDisabled={disabled}
                    className={`${selectProps.className} floating`}
                />
            </div>
        </div>
    );
}

export default StatusFilter;
