import React from 'react';
import { NavLink } from 'react-router-dom';
import { Paths } from 'app/config';

function SignedOut() {
    return <>
        <NavLink
            exact to={Paths.route('root')}
            className="nav-link"
            activeClassName="active">
            Home
        </NavLink>
        <NavLink
            to={Paths.route('accessRequest')}
            className="nav-link"
            activeClassName="active">
            Request Access
        </NavLink>
        <NavLink
            to={Paths.route('userSignin')}
            className="nav-link"
            activeClassName="active">
            Sign In
        </NavLink>
    </>;
}

export default SignedOut;
