import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Paths } from 'app/config';
import { getResizedImageURL } from 'app/lib/firebaseMethods';

function Image({profile}) {
    const [src, setSrc] = useState(null);

    useEffect(() => {
        if (profile.image) {
            const bucket = Paths.storage('profileImage', profile.uid);
            getResizedImageURL(bucket, profile.image, 600).then(s => setSrc(s));
        }
    }, [profile]);

    return (
        <Link
            className="image"
            to={Paths.route('profileHome', profile.uid)}
            style={src ? {
                backgroundImage: `url(${src})`,
                backgroundSize: 'contain'
            } : {}}
        />
    );
}

export default Image;
