import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { ContentMessage, Loading } from 'app/components/common';
import { ProfileImage } from 'app/components/Profile/Home';
import { getResizedImageMetadata } from 'app/lib/firebaseMethods';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            profile: null,
            imageMetadata: null
        };
    }
    componentDidMount() {
        this.getProfile();
    }
    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.refreshProfile();
        }
    }
    getProfile = () => {
        axios.get(Paths.service('userProfile', this.props.match.params.id)).then(r => {
            console.log('Public/Profile :: getProfile ::', r.data);
            this.setState({profile: r.data}, () => {
                if (r.data.image) this.getImageMetadata(r.data);
                else this.setState({loading: false});
            });
        }).catch(error => {
            validate.dataErrors('Public/Profile :: getProfile [Error] ::', error);
            this.setState({loading: false});
        });
    }
    getImageMetadata = profile => {
        const bucket = Paths.storage('profileImage', profile.uid);
        getResizedImageMetadata(bucket, profile.image, 300).then(r => {
            console.log('Public/Profile :: getImageMetadata ::', r);
            this.setState({imageMetadata: r.customMetadata, loading: false});
        });
    }
    refreshProfile = () => {
        this.setState({profile: null, loading: true}, this.getProfile);
    }
    render() {
        const { loading, profile, imageMetadata } = this.state;
        return (
            <div className="public-profile bounds">
                <div className="content-spacer"></div>
                {loading && <Loading padded/>}
                {!loading && profile &&
                    <div className="card">
                        <h2 className="headline">
                            Start trading wholesale with <b>{profile.business_name}</b> on Evernug.
                        </h2>
                        <p className="tagline">
                            Evernug is a free-range marketplace for licensed cannabis operators.
                        </p>
                        {profile.image && <>
                            <div className="profile-image">
                                <ProfileImage data={profile} imageMetadata={imageMetadata}/>
                            </div>
                            <div className="profile-name">{profile.business_name}</div>
                        </>}
                        <div className="actions">
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => this.props.history.push(Paths.route('userSignin'))}>
                                Sign In
                            </button>
                            <div className="request-access text-small">
                                Not a member? <Link to={Paths.route('accessRequest')}>Request Access</Link>
                            </div>
                        </div>
                    </div>
                }
                {!loading && !profile &&
                    <ContentMessage message="Profile not found." padded/>
                }
            </div>
        );
    }
}

export default Profile;
