import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { IconClose, IconUpload } from 'app/assets/icons';

function FileDrop({disabled, files, label, onDrop, onRemove, options = {}}) {
    const [error, setError] = useState(null);
    const [imageURI, setImageURI] = useState(null);
    const accept = options.accept || ['image/jpeg', 'image/png'];
    const showPreview = 'showPreview' in options ? options.showPreview : true;
    const params = {
        accept: accept.join(','),
        multiple: 'multiple' in options ? options.multiple : false,
        onDrop: useCallback((accepted, rejected) => {
            setError(null);
            if (!accepted.length && rejected.length) {
                if (rejected.length === 1) return setError('not an accepted file type');
                if (rejected.length && !options.multiple) return setError('multiple files not permitted');
            }
            const mb = 10;
            const maxFileSize = mb * 1048576 // n * 1 megabyte (in bytes)
            if (accepted[0].size > maxFileSize) setError(`file size must not exceed ${mb}MB`);
            else onDrop(accepted);
        }, [onDrop, options])
    };

    useEffect(() => {
        if (showPreview && files.length) {
            let reader = new FileReader();
            reader.onload = e => setImageURI(e.target.result);
            reader.readAsDataURL(files[0]);
        }
    }, [files, showPreview]);

    function handleRemove(e) {
        e.stopPropagation();
        setImageURI(null);
        onRemove();
    }
    const { getRootProps, getInputProps } = useDropzone(params);
    return (
        <div className={classNames('file-drop', {disabled})} {...getRootProps()}>
            <input {...getInputProps()} disabled={disabled}/>
            <div className="cols">
                <div className="col col-label">
                    {!error &&
                        <div className="label ellipsis">
                            {!!files.length
                                ? files[0].name
                                : <i>{label !== undefined ? label : 'Drop your file here or click to browse'}</i>
                            }
                        </div>
                    }
                    {error && <span className="error">{error}</span>}
                </div>
                {!files.length
                    ? <div className="icon"><IconUpload/></div>
                    : <div className="icon remove" onClick={handleRemove}><IconClose/></div>
                }
            </div>
            {showPreview && imageURI &&
                <div className="image-preview">
                    <img src={imageURI} alt=""/>
                </div>
            }
        </div>
    );
}

export default FileDrop;
