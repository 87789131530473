import React, { Component } from 'react';
import axios from 'app/lib/axios';
import { Paths } from 'app/config';
import validate from 'app/lib/validate';

class UpdateEmailLists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true,
            updating: false
        };
    }
    updateEmailLists = () => {
        axios.get(Paths.service('updateEmailLists')).then(r => {
            console.log('Logout/UpdateEmailLists :: updateEmailLists ::', r.data);
            this.setState({data: r.data, loading: false})
        }).catch(error => {
            validate.dataErrors('Logout/UpdateEmailLists :: updateEmailLists [Error] ::', error);
        });
    }
    startUpdate = () => {
        this.setState({updating: true}, this.updateEmailLists);
    }
    render() {
        const { data, loading, updating } = this.state;
        return (
            <div className="update-email-lists">
                <div className="common-header cols">
                    <h2 className="col">Update Email Lists</h2>
                </div>
                {!updating &&
                    <button
                        className="btn btn-primary"
                        type="button"
                        onClick={this.startUpdate}>
                        Start Update
                    </button>
                }
                {updating &&
                    <div className="card">
                        <pre className="inner">
                            <p>Updating email lists...</p>
                            {!loading && data.message}
                        </pre>
                    </div>
                }
            </div>
        );
    }
}

export default UpdateEmailLists;
