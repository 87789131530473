import React from 'react';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { CommonForm, FieldGroup, FormErrors } from 'app/components/common';
import { fieldErrors } from 'app/lib/forms';

const messageMaxLength = 1000;

class Composer extends CommonForm {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            inputHeight: 0,
            disabled: false,
            errors: null
        }
    }
    validators = () => [
        ...validate.commonText('message', this.state.message, {max: messageMaxLength})
    ];
    submitForm = () => {
        const { activeThread, history, liftState, subscribeToThread } = this.props;
        this.pushMessage();
        axios.post(Paths.service('threadMessages', activeThread.id), {
            message: this.state.message,
            partner_uid: activeThread.partner.uid
        }).then(r => {
            console.log('Messages/Thread/Composer :: submitForm ::', r.data);
            this.setState({disabled: false, inputHeight: 0, message: ''});
            if (activeThread.id === 'new') {
                // update activeThread with new id, subscribe to thread, update url 
                const state = {activeThread: {...activeThread, id: r.data.threadId}};
                liftState(state, () => subscribeToThread(r.data.threadId));
                history.push(Paths.route('messages', r.data.threadId));
            }
        }).catch(error => {
            const errors = validate.dataErrors('Messages/Thread/Composer :: submitForm [Error] ::', error);
            this.setState({disabled: false, errors});
        });
    }
    pushMessage = () => {
        const timestamp = Date.now();
        this.props.pushMessage({
            id: timestamp,
            message: this.state.message,
            uid: this.props.user.user_id,
            created: {_seconds: timestamp / 1000}
        });
    }
    handleChange = e => {
        const { offsetHeight, scrollHeight, value } = e.target;
        const state = {message: value};
        if (!value) state.inputHeight = 0;
        if (scrollHeight > offsetHeight && this.state.inputHeight !== scrollHeight) {
            state.inputHeight = scrollHeight + 4;
        }
        this.setState(state);
    }
    handleKeyDown = e => {
        const message = this.state.message.trim();
        if (e.keyCode === 13 && e.shiftKey) return this.setState({message: `${this.state.message}\n`});
        if (e.keyCode === 13 && !message) return e.preventDefault();
        if (e.keyCode === 13 && message) return this.handleSubmit();
    }
    textareaStyle = () => {
        const style = {height: `${this.state.inputHeight}px`};
        if (!this.state.message) style.lineHeight = '1.4em';
        return style;
    }
    render() {
        const { disabled, errors, message } = this.state;
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="composer cols">
                    <div className="col">
                        <FieldGroup errors={fieldErrors('message', errors)}>
                            <textarea
                                placeholder="Write a message..."
                                name="message"
                                value={message}
                                onChange={this.handleChange}
                                onKeyDown={this.handleKeyDown}
                                disabled={disabled}
                                style={this.textareaStyle()}
                                maxLength={messageMaxLength}
                                autoFocus required
                            />
                        </FieldGroup>
                        <FormErrors errors={errors}/>
                    </div>
                    <div className="actions">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={disabled || !message.trim()}>
                            Send
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default Composer;
