import React from 'react';
import { IconCannabisLeaf } from 'app/assets/icons';
const categories = require('app/data/product/categoryMap').productCategories();

function Body({data}) {
    return (
        <div className="body text-small">
            {data.category &&
                <p className="category has-icon has-icon-small">
                    <span className="icon"><IconCannabisLeaf/></span>
                    {categories[data.category]}
                </p>
            }
            {data.description &&
                <p className="description readable-text">{data.description}</p>
            }
        </div>
    );
}

export default Body;
