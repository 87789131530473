import React from 'react';
import { NavLink } from 'react-router-dom';
import { Paths } from 'app/config';
import { IconGamepad } from 'app/assets/icons';
import { UserContext } from '.';

function LogoutBar(props) {
    return (
        <div className="logout-bar">
            <div className="bounds">
                <div className="cols">
                    <UserContext {...props}/>
                    <div className="col text-right">
                        <NavLink to={Paths.route('logout')} className="icon" activeClassName="active">
                            <IconGamepad/>
                        </NavLink>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default LogoutBar;
