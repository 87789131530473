import {
    SET_ALERT,
    CLEAR_ALERT
} from '../actions/alert';

const initialState = {
    alert: false,
    type: '',
    message: ''
};

const alert = (state = initialState, action) => {
    switch (action.type) {

        case SET_ALERT:
            const alert = action.alert || {};
            return {
                alert: true,
                type: alert.type || 'error',
                message: alert.message || 'Something went wrong!'
            };

        case CLEAR_ALERT:
            return {
                ...initialState
            };

        default:
            return state;
    }
};

export default alert;
