import React from 'react';
import { FieldRow } from 'app/components/common';
import { fieldErrors } from 'app/lib/forms';

function Fields({errors, disabled, formData, handleChange}) {
    return (
        <div className="form-group">
            <FieldRow
                label="Current Password *"
                caption="Required to update your password."
                errors={fieldErrors('current_password', errors)}>
                <input type="password"
                    placeholder="Type your current password."
                    name="current_password"
                    value={formData.current_password}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldRow>
            <FieldRow
                label="New Password *"
                caption="Must be alphanumeric and at least 8 characters in length."
                errors={fieldErrors('new_password', errors)}>
                <input type="password"
                    placeholder="Type your new password."
                    name="new_password"
                    value={formData.new_password}
                    onChange={handleChange}
                    disabled={disabled}
                    autoComplete="new-password"
                    required/>
            </FieldRow>
        </div>
    );
}

export default Fields;
