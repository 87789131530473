import React from 'react';

function Attribute({label, value}) {
    return (
        <li className="item">
            <label>{label}</label>
            <span className="value">{value}</span>
        </li>
    );
}

export default Attribute;
