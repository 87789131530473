import { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

class Signout extends Component {
    componentDidMount() {
        firebase.auth().signOut()
        .then(() => console.log('Signout :: User is signed out.'))
        .catch(err => console.log('Signout [Error] ::', err));
    }
    render() {
        return null;
    }
}

export default Signout;
