import React, { Component } from 'react';
import { Paths } from 'app/config';
import { ImagePlaceholder, Loading } from 'app/components/common';
import { getResizedImageURL } from 'app/lib/firebaseMethods';

class ImageGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            activeImage: null,
            thumbMap: {},
            imageMap: {}
        };
        this.bucket = Paths.storage('productImage', props.data.uid);
    }
    componentDidMount() {
        const { images = [] } = this.props.data;
        if (images.length) this.getThumbs(images);
        else this.setState({loading: false});
    }
    getThumbs = images => {
        const thumbMap = {};
        for (const fileName of images) {
            thumbMap[fileName] = '';
            getResizedImageURL(this.bucket, fileName, 300).then(src => {
                thumbMap[fileName] = src;
                const thumbMapArray = Object.keys(thumbMap);
                if (thumbMapArray.length === images.length) {
                    this.setState({activeImage: thumbMap[thumbMapArray[0]], thumbMap, loading: false});
                    this.loadImage(thumbMapArray[0]);
                }
            });
        }
    }
    loadImage = fileName => {
        const { imageMap } = this.state;
        if (imageMap[fileName]) this.setState({activeImage: imageMap[fileName]});
        else getResizedImageURL(this.bucket, fileName, 900).then(src => {
            const im = {...imageMap};
            im[fileName] = src;
            this.setState({activeImage: src, imageMap: im});
        });
    }
    render() {
        const { activeImage, thumbMap, loading } = this.state;
        const thumbMapArray = Object.keys(thumbMap);
        return (
            <div className="image-group">
                {loading &&
                    <Loading padded/>
                }
                {!loading && activeImage &&
                    <div className="cols">
                        {thumbMapArray.length > 1 &&
                            <div className="thumbnails col">
                                {thumbMapArray.map(key =>
                                    <div key={key}
                                        className="image"
                                        onMouseEnter={() => {
                                            this.setState({activeImage: thumbMap[key]});
                                            this.loadImage(key);
                                        }}>
                                        <img src={thumbMap[key]} alt=""/>
                                    </div>
                                )}
                            </div>
                        }
                        <div className="image-large col">
                            <div className="image">
                                <img src={activeImage} alt=""/>
                            </div>
                        </div>
                    </div>
                }
                {!loading && !activeImage &&
                    <ImagePlaceholder/>
                }
            </div>
        );
    }
}

export default ImageGroup;
