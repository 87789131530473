import React, { Component } from 'react';
import queryString from 'query-string';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths, textmap } from 'app/config';
import { FormErrors, FieldGroup } from 'app/components/common';
import { fieldErrors } from 'app/lib/forms';
import { isEmailAvailable } from 'app/lib/firebaseMethods';

class RequestReset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            complete: false,
            disabled: false,
            errors: null
        };
    }
    componentDidMount() {
        const query = queryString.parse(window.location.search);
        if ('expired' in query) {
            this.setState({errors: [{message: 'your previous request may have expired'}]});
        }
    }
    handleSubmit = email => {
        axios.get(Paths.service('userRequestReset', email)).then(r => {
            console.log('RequestReset :: handleSubmit ::', r.data);
            this.setState({complete: true});
        }).catch(error => {
            const errors = validate.dataErrors('RequestReset :: handleSubmit [Error] ::', error);
            this.setState({disabled: false, errors});
        });
    }
    validateEmail = async e => {
        e.preventDefault();
        const email = e.target.email.value;
        this.setState({disabled: true, errors: null});
        const errors = [];
        const signInMethods = await isEmailAvailable(email);
        if (!signInMethods.length) {
            // return a false-positive if email does not match an account
            return this.setState({complete: true});
        }
        this.validateFields(errors, email);
    }
    validateFields = (prevErrors, email) => {
        const errors = [
            ...prevErrors,
            ...validate.email('email', email)
        ];
        if (errors.length) this.setState({disabled: false, errors});
        else this.handleSubmit(email);
    }
    render() {
        const { complete, disabled, errors } = this.state;
        return <>
            {!complete &&
                <div className="form-container centered">
                    <div className="card">
                        <form className="inner" onSubmit={this.validateEmail}>
                            <h2 className="form-title">{textmap.auth.requestReset.title}</h2>
                            <p className="sub-heading">{textmap.auth.requestReset.description}</p>
                            <FieldGroup
                                errors={fieldErrors('email', errors)}>
                                <input
                                    placeholder="email@domain.com"
                                    type="email"
                                    name="email"
                                    disabled={disabled}
                                    autoFocus required/>
                            </FieldGroup>
                            <FormErrors errors={errors}/>
                            <div className="btn-group">
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                    disabled={disabled}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            }
            {complete &&
                <div className="card message-card readable-text text-center">
                    <h1 className="common-header content-message">
                        {textmap.auth.requestReset.successTitle}
                    </h1>
                    {textmap.auth.requestReset.successDescription}
                </div>
            }
        </>;
    }
}

export default RequestReset;
