import React, { Component } from 'react';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths, textmap } from 'app/config';
import { FieldGroup, FormErrors } from 'app/components/common';

class UpdateStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: textmap.accessRequest.email[props.newStatus],
            errors: null,
            disabled: false
        };
    }
    handleSubmit = e => {
        e.preventDefault();
        this.setState({disabled: true});
        axios.put(Paths.service('accessRequest'), {
            id: this.props.request.id,
            status: this.props.newStatus,
            message: this.state.message
        }).then(r => {
            console.log('UpdateStatus :: handleSubmit ::', r.data);
            this.props.refreshAccessRequests();
        }).catch(error => {
            const errors = validate.dataErrors('UpdateStatus :: handleSubmit [Error] ::', error);
            this.setState({
                disabled: false,
                errors: errors.map(err => ({code: err.code, message: err.message}))
            });
        });
    }
    render() {
        const { newStatus } = this.props;
        const { errors, disabled, message } = this.state;
        return (
            <form className="email-message" onSubmit={this.handleSubmit}>
                <ul className="list-attributes">
                    <li className="item cols">
                        <label>Email Message</label>
                        <div className="col">
                            <FieldGroup
                                hint={<span>Status will be changed to <b className="capitalize">{newStatus}</b>.</span>}>
                                <textarea
                                    name="email_message"
                                    value={message}
                                    onChange={e => this.setState({message: e.target.value})}
                                    disabled={disabled}
                                    rows="4"
                                    placeholder="This message will be included in an email to the requestor."
                                    required autoFocus/>
                            </FieldGroup>
                            <FormErrors errors={errors}/>
                        </div>
                    </li>
                </ul>
                <div className="btn-group text-right">
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => this.props.setNewStatus('')}
                        disabled={disabled}>
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={disabled}>
                        Update Status and Send Email
                    </button>
                </div>
            </form>
        );
    }
}
export default UpdateStatus;
