import React, { Component } from 'react';
import classNames from 'classnames';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Loading } from 'app/components/common';
import { LogoutBar } from 'app/components/Logout';
import { Header, Routes, SideNav } from '.';
import { getIdTokenResult } from 'app/lib/firebaseMethods';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gated: true,
            loading: true,
            user: null
        };
    }
    componentDidMount() {
        firebase.auth().onAuthStateChanged(async authObject => {
            console.log('App :: onAuthStateChanged ::', authObject);
            const user = await getIdTokenResult();
            this.setState({user, gated: true, loading: false});
        });
    }
    liftState = (state, callback) => {
        this.setState(state, callback);
    }
    render() {
        const { gated, loading, user } = this.state;

        const Content = ({user}) => (
            <div className="content view col">
                <Routes gated={gated} liftState={this.liftState} user={user}/>
            </div>
        );
        return (
            <div className={classNames('app', {'public': !loading && !user})}>
                {user?.logout && <LogoutBar liftState={this.liftState} user={user}/>}
                <Header gated={gated} loading={loading} user={user}/>
                {loading && <Loading padded/>}
                {!loading && !user &&
                    <Content user={user}/>
                }
                {!loading && user && gated &&
                    <Content user={user}/>
                }
                {!loading && user && !gated &&
                    <div className="wrapper cols">
                        <SideNav/>
                        <Content user={user}/>
                    </div>
                }
            </div>
        );
    }
}

export default App;
