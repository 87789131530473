import React from 'react';
import { Link } from 'react-router-dom';
import { Paths } from 'app/config';
import { ContentMessage } from 'app/components/common';
import { Image } from '.';
import { productCrumbs } from 'app/data/product/categoryMap';

function ProductGrid({products}) {
    products = products || [];
    return (
        <div className="product-grid">
            {!!products.length &&
                <div className="products">
                    {products.map(p =>
                        <div className="product card" key={p.id}>
                            <Image product={p}/>
                            <div className="metadata">
                                <div className="business-name">
                                    <Link className="incognito" to={Paths.route('profileHome', p.uid)}>
                                        {p.business_name}
                                    </Link>
                                </div>
                                <h3 className="title">
                                    <Link to={Paths.route('productHome', p.id)}>
                                        {p.title}
                                    </Link>
                                </h3>
                                <div className="category">
                                    <span className="badge badge-info">
                                        {productCrumbs(p)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }
            {!products.length &&
                <ContentMessage message="No products found." padded/>
            }
        </div>
    );
}

export default ProductGrid;
