const validator = require('validator');
const codes = require('./codes');
const fields = require('./fields');
const messages = require('./messages');

/* Use "export default" with react. Use "module.exports" with nodejs. */
// module.exports = {
export default {

    codes,
    fields,
    messages,

    /* error handlers
    ---------------------------------------- */
    // handle errors where a data.errors array is expected
    dataErrors: function(msg, err = {}) {
        if (err.response && err.response.data && err.response.data.errors) {
            console.log(msg, err.response.data.errors);
            return err.response.data.errors;
        }
        else {
            console.log(msg, err);
            return [this.firebaseErrorHandler(err.code)];
        }
    },
    // map firebase error codes to custom messages
    firebaseErrorHandler: function(code = 'error') {
        return this.codes[code] || {code, message: this.messages.genericError};
    },
    // normalize error format
    formatErrors: function(errors) {
        return errors.map(err => ({
            name: err.name || '',
            code: err.code || '',
            message: err.message || ''
        }));
    },

    /* validation methods
    ---------------------------------------- */
    validate: function(name, value, params, validations) {
        if (params.required && !value) return [{name, message: this.messages.required}];
        if (!value) return [];
        return validations();
    },

    commonText: function(name, value, params = {}) {
        const p = {
            min: this.fields.commonText.length.min,
            max: this.fields.commonText.length.max,
            required: true,
            ...params
        };
        return this.validate(name, value, p, (errors = []) => {
            // validations
            if (!validator.isLength(value, {min: p.min})) {
                errors.push({name, message: this.messages.lengthMin(p.min)});
            }
            if (!validator.isLength(value, {max: p.max})) {
                errors.push({name, message: this.messages.lengthMax(p.max)});
            }
            return errors;
        });
    },

    dateFormat: function(name, value, params = {}) {
        const p = {
            format: this.fields.dateFormat.format,
            required: true,
            ...params
        };
        return this.validate(name, value, p, (errors = []) => {
            // validations
            if (!validator.isDate(value, p.format)) {
                errors.push({name, message: this.messages.dateFormat(p.format)});
            }
            return errors;
        });
    },

    dateInFuture: function(name, value, params = {}) {
        const p = {required: true, ...params};
        return this.validate(name, value, p, (errors = []) => {
            // validations
            if (!validator.isAfter(new Date(value).toDateString())) {
                errors.push({name, message: this.messages.dateInFuture});
            }
            return errors;
        });
    },

    dateInPast: function(name, value, params = {}) {
        const p = {required: true, ...params};
        return this.validate(name, value, p, (errors = []) => {
            // validations
            if (!validator.isBefore(new Date(value).toDateString())) {
                errors.push({name, message: this.messages.dateInPast});
            }
            return errors;
        });
    },

    email: function(name, value, params = {}) {
        const p = {required: true, ...params};
        return this.validate(name, value, p, (errors = []) => {
            // validations
            if (!validator.isEmail(value)) {
                errors.push({name, message: this.messages.emailInvalid});
            }
            return errors;
        });
    },

    fileExists: function(name, value) {
        const errors = [];
        if (validator.isEmpty(value)) {
            errors.push({name, message: this.messages.fileRequired});
        }
        return errors;
    },

    password: function(name, value, params = {}) {
        const p = {
            min: this.fields.password.length.min,
            max: this.fields.password.length.max,
            required: true,
            ...params
        };
        return this.validate(name, value, p, (errors = []) => {
            // validations
            if (!validator.isLength(value, {min: p.min})) {
                errors.push({name, message: this.messages.lengthMin(p.min)});
            }
            if (!validator.isLength(value, {max: p.max})) {
                errors.push({name, message: this.messages.lengthMax(p.max)});
            }
            if (!validator.matches(value, /^(?=.*[a-zA-Z])(?=.*[0-9])/)) {
                errors.push({name, message: 'must contain at least one letter and number'});
            }
            return errors;
        });
    },

    websiteURL: function(name, value, params = {}) {
        const p = {required: true, ...params};
        return this.validate(name, value, p, (errors = []) => {
            // validations
            if (!validator.isURL(value)) {
                errors.push({name, message: this.messages.urlInvalid});
            }
            return errors;
        });
    },
};
