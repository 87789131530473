import React from 'react';

function ImagePlaceholder() {
    return (
        <span className="image-placeholder">
            <img
                src="https://firebasestorage.googleapis.com/v0/b/evernug-app.appspot.com/o/public%2Fphoto-placeholder.png?alt=media&token=e06d15c4-e1bb-4f1f-b7d3-42a0255506ef"
                alt="placeholder"
            />
        </span>
    );
}

export default ImagePlaceholder;
