import { Component } from 'react';

class CommonForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            errors: null,
            formData: {}
        };
    }
    liftState = (state, callback) => {
        this.setState(state, callback);
    }
    validators = () => [];
    handleChange = e => {
        const formData = {...this.state.formData};
        formData[e.target.name] = e.target.value;
        this.setState({formData});
    }
    handleSubmit = e => {
        e && e.preventDefault();
        this.setState({disabled: true, errors: null});
        setTimeout(this.validateFields, 250);
    }
    validateFields = (prevErrors = []) => {
        const errors = [...prevErrors, ...this.validators()];
        if (errors.length) this.setState({disabled: false, errors});
        else this.submitForm();
    }
    submitForm = () => {
        console.log('A submitForm method is required.');
    }
}

export default CommonForm;
