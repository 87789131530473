import React from 'react';
import { FieldGroup, FieldGroupSelect } from '.';

function FieldRow({caption, label, ...props}) {
    return (
        <div className="field-row">
            <div className="meta">
                <label>{label}</label>
                {caption && <p className="caption">{caption}</p>}
            </div>
            {'select' in props ? <FieldGroupSelect {...props}/> : <FieldGroup {...props}/>}
        </div>
    );
}

export default FieldRow;
