import React, { Component } from 'react';
import axios from 'app/lib/axios';
import { Paths } from 'app/config';
import { ContentMessage, Loading } from 'app/components/common';
import { Form } from '.';
import validate from 'app/lib/validate';

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true
        };
    }
    componentDidMount() {
        this.getData();
    }
    getData = () => {
        axios.get(Paths.service('profile', this.props.user.user_id)).then(r => {
            console.log('Profile/Edit :: getData ::', r.data);
            this.setState({data: r.data, loading: false});
        }).catch(error => {
            validate.dataErrors('Profile/Edit :: getData [Error] ::', error);
            this.setState({loading: false});
        });
    }
    render() {
        const { data, loading } = this.state;
        return (
            <div className="profile-edit form-with-images">
                {loading && <Loading padded/>}
                {!loading && data &&
                    <Form {...this.props} data={data}/>
                }
                {!loading && !data &&
                    <ContentMessage message="Profile not found." padded/>
                }
            </div>
        );
    }
}

export default Edit;
