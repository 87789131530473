import React from 'react';
import classNames from 'classnames';

function ContentMessage(props) {
    return (
        <div className={classNames('content-message', {
            'has-padding': 'padded' in props,
            'has-less-padding': 'padded-less' in props,
            'has-error': 'error' in props
        })}>
            {props.message || 'No result found.'}
        </div>
    );
}

export default ContentMessage;
