import React from 'react';
import { withToastManager } from 'react-toast-notifications';
import { toast } from 'app/lib/helpers';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { IconUserCog } from 'app/assets/icons';
import { CommonForm } from 'app/components/common';
import { FormFooter } from '..';
import { Fields } from '.';
import { isEmailAvailable } from 'app/lib/firebaseMethods';

class Form extends CommonForm {
    state = {
        ...this.state,
        formData: {
            first_name: this.props.account.first_name || '',
            last_name: this.props.account.last_name || '',
            email: this.props.user.email || '',
            phone_number: this.props.account.phone_number || ''
        }
    };
    validators = () => [
        ...validate.commonText('first_name', this.state.formData.first_name),
        ...validate.commonText('last_name', this.state.formData.last_name),
        ...validate.email('email', this.state.formData.email),
        ...validate.commonText('phone_number', this.state.formData.phone_number)
    ];
    submitForm = () => {
        const { history, toastManager, user } = this.props;
        const payload = {...this.state.formData};
        // only submit email if changed
        if (payload.email === user.email) delete payload.email;
        axios.put(Paths.service('account'), payload).then(r => {
            console.log('Settings/Account/Form :: submitForm ::', r.data);
            // email changed: redirect to verify email page
            if (payload.email) history.push(Paths.route('userVerifyEmail', payload.email));
            else {
                toastManager.add(...toast("Success! Your settings have been updated."));
                this.setState({disabled: false});
            }
        }).catch(error => {
            const errors = validate.dataErrors('Settings/Account/Form :: submitForm [Error] ::', error);
            this.setState({disabled: false, errors});
        });
    }
    validateEmail = async e => {
        e.preventDefault();
        this.setState({disabled: true, errors: null});
        const errors = [];
        if (this.state.formData.email !== this.props.user.email) {
            const signInMethods = await isEmailAvailable(this.state.formData.email);
            if (signInMethods.length) {
                errors.push(validate.firebaseErrorHandler('auth/email-already-in-use'));
            }
        }
        this.validateFields(errors);
    }
    render() {
        const { disabled, errors, formData } = this.state;
        return (
            <div className="card">
                <form onSubmit={this.validateEmail}>
                    <h3 className="form-title has-icon">
                        <span className="icon flip-h">
                            <IconUserCog/>
                        </span>
                        My Account
                    </h3>
                    <Fields
                        disabled={disabled}
                        errors={errors}
                        formData={formData}
                        handleChange={this.handleChange}
                    />
                    <FormFooter
                        disabled={disabled}
                        errors={errors}
                    />
                </form>
            </div>
        );
    }
}

export default withToastManager(Form);
