import React, { Component } from 'react';
import axios from 'app/lib/axios';
import { Paths } from 'app/config';
import { Loading } from 'app/components/common';
import { ProductGrid } from 'app/components/Products';
import { Header } from '.';
import validate from 'app/lib/validate';

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true
        };
    }
    componentDidMount() {
        this.getData();
    }
    getData = () => {
        axios.get(Paths.service('userProducts', this.props.profile.uid)).then(r => {
            console.log('Profile/Home/Products :: getData ::', r.data);
            this.setState({data: r.data, loading: false});
        }).catch(error => {
            validate.dataErrors('Profile/Home/Products :: getData [Error] ::', error);
            this.setState({loading: false});
        });
    }
    render() {
        const { data, loading } = this.state;
        return loading ? <Loading padded/> : (
            <div className="profile-products">
                <Header {...this.props}/>
                <ProductGrid products={data}/>
            </div>
        );
    }
}

export default Products;
