import React from 'react';
import { SearchBar, SignedIn, SignedOut } from '.';

function Nav(props) {
    return (
        <div className="col col-nav">
            {props.user && <SearchBar/>}
            <div className="nav-bar">
                {props.user ? <SignedIn {...props}/> : <SignedOut/>}
            </div>
        </div>
    );
}

export default Nav;
