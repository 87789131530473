import React from 'react';
import { NavLink } from 'react-router-dom';
import { Paths } from 'app/config';
import { IconServiceBell } from 'app/assets/icons';
import serviceCategories from 'app/data/serviceCategories';

function ServiceNav() {
    return <>
        <NavLink
            exact to={Paths.route('serviceRequests')}
            className="nav-link"
            activeClassName="active"
            isActive={(match, location) => location.pathname.startsWith('/service')}>
            <span className="icon"><IconServiceBell/></span>
            <span className="label">Services</span>
        </NavLink>
        <div className="sub-nav">
            {Object.keys(serviceCategories).map(key =>
                <NavLink
                    key={key}
                    to={Paths.route('serviceRequests', key)}
                    className="nav-link"
                    activeClassName="active">
                    <span className="label">
                        {serviceCategories[key]}
                    </span>
                </NavLink>
            )}
        </div>
    </>;
}

export default ServiceNav;
