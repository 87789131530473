import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Paths } from 'app/config';
import { ImageAsBackground, TimeDistance } from 'app/components/common';

function Row({liftState, thread: t, user}) {
    return (
        <NavLink
            key={t.id}
            to={Paths.route('messages', t.id)}
            onClick={() => liftState({activeThread: t})}
            className="row"
            activeClassName="active">
            <div className="profile-image">
                <ImageAsBackground
                    image={t.partner.image}
                    path="profileImage"
                    pathParams={t.partner.uid}
                    size={300}
                />
            </div>
            <div className={classNames('meta', {'has-unread': t.unread === user.user_id})}>
                <div className="business-name ellipsis">
                    {t.partner.business_name}
                </div>
                {t.lastMessage?.message
                    ? <div className="message text-muted ellipsis">{t.lastMessage.message}</div>
                    : <i className="text-small text-muted">no messages</i>
                }
                {t.lastMessage?.created &&
                    <TimeDistance timestamp={t.lastMessage.created._seconds}/>
                }
            </div>
        </NavLink>
    );
}

export default Row;
