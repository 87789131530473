import React from 'react';
import classNames from 'classnames';
import { TimeDistance } from 'app/components/common';

function Message({activeThread, message: m, profile}) {
    const owner = m.uid === profile.uid ? profile : activeThread.partner;
    const style = owner.imageDownloadURL ? {backgroundImage: `url(${owner.imageDownloadURL})`} : {};
    return (
        <div className={classNames('row', {'reverse': m.uid === profile.uid})}>
            <div className="profile-image">
                <span className="image image-as-background" style={style}/>
            </div>
            <div className="meta">
                <div className="message-body readable-text line-height">
                    {m.message}
                </div>
                {m.created._seconds &&
                    <TimeDistance timestamp={m.created._seconds}/>
                }
            </div>
        </div>
    );
}

export default Message;
