import React from 'react';
import { Link } from 'react-router-dom';
import { Paths } from 'app/config';
import { IconCannabisLeaf } from 'app/assets/icons';

function Banner({user}) {
    return (
        <div className="card feature-card message-card readable-text">
            <h3 className="heading has-icon cols">
                <span className="icon"><IconCannabisLeaf/></span>
                <span className="col">Post Your Products And Contribute To The Marketplace</span>
            </h3>
            <span className="text-small line-height">
                Once you <Link to={Paths.route('productAdd')}>add your products</Link> they'll
                be visible here in the product catalog and
                on <Link to={Paths.route('profileHome', user.user_id)}>your profile</Link>.
                Interested buyers can then contact you directly
                via <Link to={Paths.route('messages')}>Messages</Link> and you can
                work together to form a deal. Then it'll be up to you and your
                distributor to get it sorted through <em>metrc</em>, receive
                payment and handle delivery to the buyer.
                <br/><br/>
                There are no hidden fees or additional setup required.
                If you have any questions reach out directly
                to <a href="mailto:rich@evernug.com">rich@evernug.com</a>
            </span>
        </div>
    );
}

export default Banner;
