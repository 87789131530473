import { combineReducers } from 'redux';
import alert from './alert';
import clickCount from './clickCount';
import config from './config';
import user from './user';

export default combineReducers({
    alert,
    clickCount,
    config,
    user
});
