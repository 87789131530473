import React, { Component } from 'react';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import { Paths } from 'app/config';
import { IconSearch } from 'app/assets/icons';

class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasFocus: false,
            value: ''
        };
    }
    componentDidUpdate(prevProps) {
        const { location } = this.props;
        // clear search input on path change except when searching
        if (!location.state?.searchPath && location.pathname !== prevProps.location.pathname) {
            this.setState({value: ''});
        }
    }
    handleSubmit = e => {
        e.preventDefault();
        const { value } = this.state;
        const cleanQuery = value.replace(/[^a-z0-9-]+|\s+/gi, " ").trim();
        if (cleanQuery) {
            const state = {
                searchPath: this.props.location.state?.searchPath || 'searchProducts',
                searchQuery: value
            };
            const path = Paths.route(state.searchPath, encodeURIComponent(cleanQuery), {state});
            this.props.history.push(path);
        }
    }
    render() {
        const { hasFocus, value } = this.state;
        return (
            <form className={classNames('search-bar', {'has-focus': hasFocus})}
                onSubmit={this.handleSubmit}>
                <label htmlFor="search-bar" className="icon">
                    <IconSearch/>
                </label>
                <input type="text"
                    placeholder="search products & profiles"
                    id="search-bar"
                    name="search"
                    value={value}
                    onChange={e => this.setState({value: e.target.value})}
                    onFocus={() => this.setState({hasFocus: true})}
                    onBlur={() => this.setState({hasFocus: false})}
                    maxLength="64"
                />
            </form>
        );
    }
}

export default withRouter(SearchBar);
