import React, { useState } from 'react';
import { UpdateStatus } from '.';

function Footer(props) {
    const [newStatus, setNewStatus] = useState('');
    const { request } = props;
    return (
        <div className="footer">
            {!newStatus && ['pending', 'denied'].includes(request.status) &&
                <div className="btn-group text-right">
                    {request.status === 'pending' &&
                        <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() => setNewStatus('denied')}>
                            Deny
                        </button>
                    }
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => setNewStatus('approved')}>
                        Approve
                    </button>
                </div>
            }
            {newStatus &&
                <UpdateStatus
                    {...props}
                    newStatus={newStatus}
                    setNewStatus={setNewStatus}
                />
            }
        </div>
    );
}

export default Footer;
