export const getAlert = state => {
    return state.alert;
};

export const getClickCount = state => {
    return state.clickCount;
};

export const getConfig = state => {
    return state.config;
};

export const getUser = state => {
    return state.user;
};
