import React from 'react';
import { Link } from 'react-router-dom';
import { Paths } from 'app/config';
import { EvernugLogo } from 'app/assets';

function Footer() {
    return (
        <div className="public-footer bounds">
            <div className="nav">
                <Link className="light" to={Paths.route('root')}>Home</Link>
                <Link className="light" to={Paths.route('accessRequest')}>Request Access</Link>
                <Link className="light" to={Paths.route('userSignin')}>Sign In</Link>
                <Link className="light" to={Paths.route('policyDisclaimer')}>Disclaimer</Link>
                <Link className="light" to={Paths.route('policyPrivacy')}>Privacy</Link>
                <Link className="light" to={Paths.route('policyTerms')}>Terms</Link>
            </div>
            <div className="logo-wrapper">
                <Link to={Paths.route('root')} className="logo">
                    <EvernugLogo/>
                </Link>
            </div>
            <div className="copyright">&copy; {new Date().getFullYear()}</div>
        </div>
    );
}

export default Footer;
