import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { EvernugLogo } from 'app/assets';
import { Nav } from '.';
import { Paths } from 'app/config';

function Header(props) {
    return (
        <header className={classNames('header', {'has-user': props.user})}>
            <div className="cols">
                <Link to={Paths.route('root')} className="logo">
                    <EvernugLogo/>
                </Link>
                {!props.loading && <Nav {...props}/>}
            </div>
        </header>
    );
}

export default Header;
