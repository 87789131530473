import React from 'react';
import { CharacterLimit, FieldGroup } from 'app/components/common';
import { fieldErrors } from 'app/lib/forms';

function Fields({disabled, errors, formData, handleChange}) {
    return (
        <div className="form-group">
            <FieldGroup
                label="Business Name"
                errors={fieldErrors('business_name', errors)}>
                <input type="text"
                    name="business_name"
                    value={formData.business_name}
                    onChange={handleChange}
                    disabled={disabled}
                    autoFocus required/>
            </FieldGroup>
            <FieldGroup
                label="Description"
                errors={fieldErrors('description', errors)}
                hint={<CharacterLimit value={formData.description} limit={1000}/>}>
                <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    disabled={disabled}/>
            </FieldGroup>
            <FieldGroup
                label="Website URL"
                errors={fieldErrors('website_url', errors)}>
                <input type="text"
                    name="website_url"
                    value={formData.website_url}
                    onChange={handleChange}
                    disabled={disabled}/>
            </FieldGroup>
        </div>
    );
}

export default Fields;
