import React from 'react';
import store from 'redux/store';
import { setConfig } from 'redux/actions/config';
import { SelectProfile } from '.';

function UserContext({liftState, user}) {
    // set user context in redux and App component
    function handleChange(selected) {
        store.dispatch(setConfig({userContext: selected.value}));
        liftState({user: {...user, user_id: selected.value}});
    }
    return (
        <div className="col">
            <div className="cols">
                <span className="label">
                    User Context
                </span>
                <SelectProfile
                    handleChange={handleChange}
                    value={user.user_id}
                />
            </div>
        </div>
    );
}

export default UserContext;
