import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { ToastProvider } from 'react-toast-notifications';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'redux/store';
import { FirebaseAppProvider } from 'reactfire';
import firebaseConfig from 'app/config/firebaseConfig';
import { ScrollToTop } from 'app/components/common';
import { App } from 'app/components';
import 'app/css/main.scss';

// disable console.log in production
if (firebaseConfig.projectId === 'evernug-app') console.log = () => false;

ReactDOM.render(
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <Provider store={store}>
            <BrowserRouter>
                <ToastProvider>
                    <ScrollToTop/>
                    <App/>
                </ToastProvider>
            </BrowserRouter>
        </Provider>
    </FirebaseAppProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
