import React, { Component } from 'react';
import axios from 'app/lib/axios';
import { Paths } from 'app/config';
import { ContentMessage, Loading } from 'app/components/common';
import { Form } from '.';
import validate from 'app/lib/validate';

class AddEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loading: true
        };
    }
    componentDidMount() {
        if (this.props.action === 'edit') {
            this.getData();
        }
        else this.setState({loading: false});
    }
    getData = () => {
        axios.get(Paths.service('serviceRequests', this.props.match.params.id)).then(r => {
            console.log('Services/AddEdit :: getData ::', r.data);
            this.setState({data: r.data, loading: false});
        }).catch(error => {
            validate.dataErrors('Services/AddEdit :: getData [Error] ::', error);
            this.setState({data: null, loading: false});
        });
    }
    render() {
        const { data, loading } = this.state;
        return (
            <div className="service-add-edit">
                {loading && <Loading padded/>}
                {!loading && data &&
                    <Form {...this.props} data={data}/>
                }
                {!loading && !data &&
                    <ContentMessage message="Service Request not found." padded/>
                }
            </div>
        );
    }
}

export default AddEdit;
