import aromas from './aromas';
import edibleTypes from './edibleTypes';
import extractTypes from './extractTypes';
import growEnvironments from './growEnvironments';
import growthTypes from './growthTypes';
import pillForms from './pillForms';
import strainDescriptors from './strainDescriptors';
import tinctureTypes from './tinctureTypes';

const categoryMap = {

    "edible": {
        label: "Edible",
        attributes: {
            "edible_type": {
                label: "Edible Type",
                map: edibleTypes
            }
        }
    },
    "extract": {
        label: "Extract",
        attributes: {
            "extract_type": {
                label: "Extract Type",
                map: extractTypes
            }
        }
    },
    "flower": {
        label: "Flower",
        attributes: {
            "grow_environment": {
                label: "Environment",
                map: growEnvironments
            },
            "strain_descriptor": {
                label: "Strain Descriptor",
                map: strainDescriptors
            }
        }
    },
    "nursery": {
        label: "Nursery",
        attributes: {
            "growth_type": {
                label: "Growth Type",
                map: growthTypes
            }
        }
    },
    "pill": {
        label: "Pill",
        attributes: {
            "pill_form": {
                label: "Pill Form",
                map: pillForms
            }
        }
    },
    "terpene": {
        label: "Terpene",
        attributes: {
            "aroma": {
                label: "Aroma",
                map: aromas
            }
        }
    },
    "tincture": {
        label: "Tincture",
        attributes: {
            "tincture_type": {
                label: "Tincture Type",
                map: tinctureTypes
            }
        }
    }
};

export function productCategories() {
    const map = {};
    Object.keys(categoryMap).forEach(key => map[key] = categoryMap[key].label);
    return map;
}

export function productCrumbs(product) {
    let str = categoryMap[product.category].label;
    Object.keys(product.attributes).forEach(key => {
        str = str + ` / ${categoryMap[product.category].attributes[key].map[product.attributes[key]]}`;
    });
    return str;
}

export default categoryMap;
