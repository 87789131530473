import React, { Component } from 'react';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { ContentMessage, Loading } from 'app/components/common';
import { Form } from 'app/components/Profile/Edit';
import { connect } from  'react-redux';
import { getConfig } from 'redux/selectors';
import { setConfig } from 'redux/actions/config';

class ProfileGate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            profile: null
        };
    }
    componentDidMount() {
        if (this.props.reduxConfig.newUser) {
            this.getProfile();
            this.sendWelcomeMessage();
        }
        else this.props.removeGate('profileGate');
    }
    getProfile = () => {
        axios.get(Paths.service('profile', this.props.user.user_id)).then(r => {
            console.log('Gateway/ProfileGate :: getProfile ::', r.data);
            this.setState({profile: r.data, loading: false});
        }).catch(error => {
            validate.dataErrors('Gateway/ProfileGate :: getProfile [Error] ::', error);
        });
    }
    sendWelcomeMessage = () => {
        axios.post(Paths.service('threadMessages', 'welcome')).then(r => {
            console.log('Gateway/ProfileGate :: sendWelcomeMessage ::', r.data);
        }).catch(error => {
            validate.dataErrors('Gateway/ProfileGate :: sendWelcomeMessage [Error] ::', error);
        });
    }
    removeGate = () => {
        this.props.setConfig({newUser: false});
        this.props.removeGate('profileGate');
    }
    render() {
        const { loading, profile } = this.state;
        return loading ? <Loading padded/> : (
            <div className="profile-gate form-with-images container">
                <ContentMessage message={<>
                    <p>Complete your profile to get started.</p>
                    <span>This is what other users on Evernug will see!</span>
                </>}/>
                <Form
                    {...this.props}
                    data={profile}
                    successCallback={this.removeGate}
                    SkipButton={() => (
                        <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={this.removeGate}>
                            Skip
                        </button>
                    )}
                />
                <div className="content-spacer"></div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    reduxConfig: getConfig(state)
});
const mapDispatchToProps = { setConfig };
export default connect(mapStateToProps, mapDispatchToProps)(ProfileGate);
