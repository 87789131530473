import React from 'react';

function Pending() {
    return (
        <div className="card message-card readable-text text-center">
            <h1 className="common-header content-message">Access Request Received</h1>
            <p>
                Thank you for your interest in joining Evernug!
                We're in the process of reviewing your request.
            </p>
            We'll get back to you shortly!
        </div>
    );
}

export default Pending;
