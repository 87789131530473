import React, { Component } from 'react';
import axios from 'app/lib/axios';
import { Paths } from 'app/config';
import { FormErrors } from 'app/components/common';
import { Fields, Pending } from '.';
import { isEmailAvailable } from 'app/lib/firebaseMethods';
import validate from 'app/lib/validate';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            complete: false,
            disabled: false,
            errors: null,
            formData: {
                license_number: '',
                license_type: '',
                license_expiration_date: '',
                license_file: '',
                business_name: '',
                first_name: '',
                last_name: '',
                email: '',
                phone_number: ''
            },
            files: []
        };
    }
    liftState = (state, callback) => {
        this.setState(state, callback);
    }
    handleSubmit = () => {
        axios.post(Paths.service('requestAccess'), this.getPayload()).then(r => {
            console.log('RequestAccess :: handleSubmit ::', r.data);
            this.setState({complete: true});
        }).catch(error => {
            const errors = validate.dataErrors('RequestAccess :: handleSubmit [Error] ::', error);
            this.setState({disabled: false, errors});
        });
    }
    getPayload = () => {
        const formData = {...this.state.formData};
        const payload = new FormData();
        for (const key in formData) payload.append(key, formData[key]);
        payload.append('license_file', this.state.files[0]);
        return payload;
    }
    handleChange = e => {
        const formData = {...this.state.formData};
        formData[e.target.name] = e.target.value;
        this.setState({formData});
    }
    validateEmail = async e => {
        e.preventDefault();
        this.setState({disabled: true, errors: null});
        const errors = [];
        const signInMethods = await isEmailAvailable(this.state.formData.email);
        if (signInMethods.length) {
            errors.push(validate.firebaseErrorHandler('auth/email-already-in-use'));
        }
        this.validateFields(errors);
    }
    validateFields = prevErrors => {
        const { formData: a } = this.state;
        const errors = [
            ...prevErrors,
            ...validate.commonText('license_number', a.license_number),
            ...validate.commonText('license_type', a.license_type),
            ...validate.dateFormat('license_expiration_date', a.license_expiration_date),
            ...validate.dateInFuture('license_expiration_date', a.license_expiration_date),
            ...validate.fileExists('license_file', this.state.files[0] ? this.state.files[0].name : ''),
            ...validate.commonText('business_name', a.business_name),
            ...validate.commonText('first_name', a.first_name),
            ...validate.commonText('last_name', a.last_name),
            ...validate.email('email', a.email),
            ...validate.commonText('phone_number', a.phone_number)
        ];
        if (errors.length) this.setState({disabled: false, errors});
        else this.handleSubmit();
    }
    render() {
        const { complete, disabled, errors } = this.state;
        return complete ? <Pending/> : (
            <div className="form-container centered">
                <div className="card">
                    <form className="inner" onSubmit={this.validateEmail}>
                        <h3 className="form-title">Request Access</h3>
                        <p className="sub-heading">
                            We are currently only enrolling California
                            businesses with an active cannabis license.
                        </p>
                        <Fields
                            disabled={disabled}
                            errors={errors}
                            formData={this.state.formData}
                            files={this.state.files}
                            handleChange={this.handleChange}
                            liftState={this.liftState}
                        />
                        <FormErrors errors={errors}/>
                        <div className="btn-group">
                            <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={disabled}>
                                Request Access
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Form;
