import { useEffect } from 'react';
import { Paths } from 'app/config';

function EmailGate({history, removeGate, user}) {

    useEffect(() => {
        if (user.email_verified) removeGate('emailGate');
        else history.push(Paths.route('userVerifyEmail', user.email));
    }, [history, removeGate, user]);

    return null;
}

export default EmailGate;
