import React from 'react';
import { NavLink } from 'react-router-dom';
import { Paths } from 'app/config';

function Menu({user}) {
    return (
        <div className="menu view col">
            <div className="nav">
                <NavLink
                    exact to={Paths.route('logoutRequests')}
                    className="nav-link"
                    activeClassName="active">
                    Access Requests
                </NavLink>
                <NavLink
                    exact to={Paths.route('logoutLicenses')}
                    className="nav-link"
                    activeClassName="active">
                    Licenses
                </NavLink>
                <NavLink
                    exact to={Paths.route('logoutLookup')}
                    className="nav-link"
                    activeClassName="active">
                    User Lookup
                </NavLink>
                <NavLink
                    exact to={Paths.route('logoutUpdateEmailLists')}
                    className="nav-link"
                    activeClassName="active">
                    Update Email Lists
                </NavLink>
            </div>
        </div>
    );
}

export default Menu;
