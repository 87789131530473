import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { Actions, Expiration } from '.';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null
        };
    }
    componentDidMount() {
        this.getProfile();
    }
    getProfile = () => {
        axios.get(Paths.service('profile', this.props.data.uid)).then(r => {
            console.log('Services/Item :: getProfile ::', r.data);
            this.setState({profile: r.data});
        }).catch(error => {
            validate.dataErrors('Services/Item :: getProfile [Error] ::', error);
            this.setState({loading: false});
        });
    }
    postedBy = () => {
        const { profile } = this.state;
        if (!profile) return '';
        return profile.uid === this.props.user.user_id ? 'you' : profile.business_name;
    }
    render() {
        const { profile } = this.state;
        return (
            <div className="footer cols">
                <div className="col text-small">
                    Posted by {profile && <Link to={Paths.route('profileHome', profile.uid)}>{this.postedBy()}</Link>}
                    <Expiration timestamp={this.props.data.created._seconds}/>
                </div>
                <Actions {...this.props} profile={profile}/>
            </div>
        );
    }
}

export default Footer;
