import React from 'react';
import { FormErrors } from 'app/components/common';

function FormFooter({disabled, errors, label}) {
    return (
        <div className="field-row-footer">
            <div className="meta"></div>
            <div className="form-group">
                <FormErrors errors={errors}/>
                <div className="actions">
                    <div className="btn-group text-right">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={disabled}>
                            {label || 'Save Changes'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FormFooter;
