import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Auth, Gateway, Logout, Messages, Product, Products, Profile, Profiles, RequestAccess, Search, Services, Settings } from '.';
import { Home, Policies, PublicProfile } from 'app/components/Public';
import { Paths } from 'app/config';

function Routes({gated, liftState, user}) {
    const defaultRoutes = [
        <Route
            key="auth"
            path={Paths.absolute('auth')}
            render={routerProps => <Auth {...routerProps} user={user}/>}
        />
    ];
    const publicRoutes = [
        <Route
            key="access"
            path={Paths.absolute('access')}
            render={routerProps => <RequestAccess {...routerProps}/>}
        />,
        <Route
            key="policies"
            path={Paths.absolute('policies')}
            render={routerProps => <Policies {...routerProps}/>}
        />,
        <Route
            key="profile"
            path={Paths.absolute('profileHome', ':id')}
            render={routerProps => <PublicProfile {...routerProps}/>}
        />,
        <Route
            key="root"
            exact path={Paths.absolute('root')}
            render={routerProps => <Home {...routerProps}/>}
        />
    ];
    const gatewayRoutes = [
        <Route
            key="gateway"
            path={Paths.absolute('root')}
            render={routerProps => <Gateway {...routerProps} liftState={liftState} user={user}/>}
        />
    ];
    const privateRoutes = [
        <Route
            key="messages"
            path={Paths.absolute('messages', ':id?')}
            render={routerProps => <Messages {...routerProps} user={user}/>}
        />,
        <Route
            key="product"
            path={Paths.absolute('product')}
            render={routerProps => <Product {...routerProps} user={user}/>}
        />,
        <Route
            key="services"
            path={Paths.absolute('serviceRequests', ':category?')}
            render={routerProps => <Services {...routerProps} user={user}/>}
        />,
        <Route
            key="products"
            path={Paths.absolute('products', ':category?')}
            render={routerProps => <Products {...routerProps} user={user}/>}
        />,
        <Route
            key="profile"
            path={Paths.absolute('profile')}
            render={routerProps => <Profile {...routerProps} user={user}/>}
        />,
        <Route
            key="profiles"
            path={Paths.absolute('profiles', ':category?')}
            render={routerProps => <Profiles {...routerProps}/>}
        />,
        <Route
            key="search"
            path={Paths.absolute('search')}
            render={routerProps => <Search {...routerProps}/>}
        />,
        <Route
            key="settings"
            path={Paths.absolute('settings')}
            render={routerProps => <Settings {...routerProps} user={user}/>}
        />,
        <Route
            key="root"
            exact path={Paths.absolute('root')}
            render={() => <Redirect to={Paths.absolute('products')}/>}
        />
    ];
    const logoutRoutes = [
        <Route
            key="logout"
            path={Paths.absolute('logout')}
            render={routerProps => <Logout {...routerProps} user={user}/>}
        />
    ];
    return (
        <Switch>
            {defaultRoutes.map(route => route)}
            {!user &&
                publicRoutes.map(route => route)
            }
            {user && gated &&
                gatewayRoutes.map(route => route)
            }
            {user && !gated &&
                privateRoutes.map(route => route)
            }
            {user && user.logout &&
                logoutRoutes.map(route => route)
            }
            <Redirect to={Paths.absolute('root')}/>
        </Switch>
    );
}

export default Routes;
