import React, { Component } from 'react';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { Loading } from 'app/components/common';
import { Form } from '.';

class License extends Component {
    constructor(props) {
        super(props);
        this.state = {
            license: null,
            loading: true
        }
    }
    componentDidMount() {
        this.getLicense();
    }
    getLicense = () => {
        axios.get(Paths.service('license')).then(r => {
            console.log('Settings/License :: getLicense ::', r.data);
            this.setState({license: r.data, loading: false}, () => {
                const { getLicenseCallback } = this.props;
                getLicenseCallback && getLicenseCallback(r.data);
            });
        }).catch(error => {
            validate.dataErrors('Settings/License :: getLicense [Error] ::', error);
        });
    }
    render() {
        const { license, loading } = this.state;
        return loading
            ? <Loading padded/>
            : <Form {...this.props} license={license}/>;
    }
}

export default License;
