import React, { Component } from 'react';
import axios from 'app/lib/axios';
import { Paths } from 'app/config';
import validate from 'app/lib/validate';
import Select, { selectProps } from 'app/components/common/Select';

class SelectProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            options: []
        };
    }
    componentDidMount() {
        this.getProfiles();
    }
    getProfiles = () => {
        axios.get(Paths.service('profiles')).then(r => {
            console.log('SelectProfile :: getProfiles ::', r.data);
            this.setState({
                loading: false,
                options: r.data.map(p => ({label: `${p.business_name} (${p.uid})`, value: p.uid}))
            });
        }).catch(error => {
            validate.dataErrors('SelectProfile :: getProfiles [Error] ::', error);
            this.setState({loading: false});
        });
    }
    render() {
        const { handleChange, value } = this.props;
        const { loading, options } = this.state;
        return (
            <div className="col">
                <Select
                    {...selectProps}
                    options={options}
                    value={options.find(o => o.value === value)}
                    onChange={handleChange}
                    isLoading={loading}
                />
            </div>
        );
    }
}

export default SelectProfile;
