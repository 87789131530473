import React, { Component } from 'react';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { Results } from '.';

class Profiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            errors: null,
            loading: true
        };
    }
    componentDidMount() {
        this.searchDocs(this.props.match.params.query);
    }
    componentDidUpdate(prevProps) {
        if (this.props.match.params.query !== prevProps.match.params.query) {
            this.refreshSearch(this.props.match.params.query);
        }
    }
    searchDocs = query => {
        const params = {query};
        axios.get(Paths.service('search', 'profiles'), {params}).then(r => {
            console.log('Search/Profiles :: searchDocs ::', r.data);
            this.setState({data: r.data, loading: false});
        }).catch(error => {
            const errors = validate.dataErrors('Search/Profiles :: searchDocs [Error] ::', error);
            this.setState({errors});
        });
    }
    refreshSearch = query => {
        this.setState({data: null, loading: true}, () => this.searchDocs(query));
    }
    render() {
        const { data, errors, loading } = this.state;
        return (
            <Results
                {...this.props}
                data={data}
                errors={errors}
                loading={loading}
                source="profiles"
            />
        );
    }
}

export default Profiles;
