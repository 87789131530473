import Settings from './Settings';

export { default as Account } from './Account';
export { default as Badges } from './Badges';
export { default as FormFooter } from './FormFooter';
export { default as License } from './License';
export { default as Nav } from './Nav';
export { default as Password } from './Password';

export default Settings;
