import React from 'react';
import { NavLink } from 'react-router-dom';
import { Paths } from 'app/config';
import { IconBook, IconCertificate, IconHand, IconLock } from 'app/assets/icons';

function PoliciesNav() {
    return (
        <div className="settings-nav">
            <div className="side-nav card">
                <div className="nav-section">
                    <h3 className="header has-icon">
                        <span className="icon"><IconCertificate/></span>
                        Policies
                    </h3>
                    <NavLink
                        to={Paths.route('policyDisclaimer')}
                        className="nav-link"
                        activeClassName="active">
                        <span className="icon flip-h"><IconHand/></span>
                        <span className="label">Disclaimer</span>
                    </NavLink>
                    <NavLink
                        to={Paths.route('policyPrivacy')}
                        className="nav-link license-link"
                        activeClassName="active">
                        <span className="icon"><IconLock/></span>
                        <span className="label">Privacy</span>
                    </NavLink>
                    <NavLink
                        to={Paths.route('policyTerms')}
                        className="nav-link"
                        activeClassName="active">
                        <span className="icon"><IconBook/></span>
                        <span className="label">Terms</span>
                    </NavLink>
                </div>
            </div>
        </div>
    );
}

export default PoliciesNav;
