import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { IconBadge } from 'app/assets/icons';
import { FieldGroup } from 'app/components/common';
import { Badge01, Badge02, Badge03, Badge04 } from '.';

function Badges({user}) {
    const badgeComponents = [<Badge03/>, <Badge01/>, <Badge02/>, <Badge04/>];
    function generateHTML(svg) {
        const style = `className="evernug-badge" border="0" style="display:inline-block;width:200px"`;
        const target = `target="_blank" rel="noopener noreferrer"`;
        return `<a href="https://evernug.com/profile/${user.user_id}" ${style} ${target}>${ReactDOMServer.renderToString(svg).replace(` data-reactroot=""`, '')}</a>`;
    }
    return (
        <div className="settings-badges card">
            <h3 className="form-title has-icon">
                <span className="icon">
                    <IconBadge/>
                </span>
                Badges
            </h3>
            {badgeComponents.map((badge, i) => (
                <div className="field-row" key={i}>
                    <div className="svg-badge">{badge}</div>
                    <div className="code-snippet">
                        <FieldGroup
                            hint={<span>Copy and paste this HTML into your website code.</span>}>
                            <textarea
                                onClick={e => e.target.select()}
                                defaultValue={generateHTML(badge)}
                                rows="4"/>
                        </FieldGroup>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Badges;
