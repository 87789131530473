import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import { FormErrors, FieldGroup } from 'app/components/common';
import { Paths } from 'app/config';
import validate from 'app/lib/validate';

class Signin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            errors: null
        };
    }
    handleSubmit = e => {
        e.preventDefault();
        this.setState({disabled: true, errors: null});
        const { email, password } = e.target;
        firebase.auth().signInWithEmailAndPassword(email.value, password.value)
        .then(user => {
            console.log('Signin :: handleSubmit ::', user);
        })
        .catch(err => {
            console.log('Signin :: handleSubmit [Error] ::', err);
            this.setState({disabled: false, errors: [validate.firebaseErrorHandler(err.code)]});
        });
    }
    render() {
        return (
            <div className="sign-in form-container centered">
                <div className="card">
                    <form className="inner" onSubmit={this.handleSubmit}>
                        <h2 className="form-title">Sign In</h2>
                        <FieldGroup label="Email Address">
                            <input type="email" name="email" autoFocus required/>
                        </FieldGroup>
                        <FieldGroup label="Password">
                            <input type="password" name="password" required/>
                        </FieldGroup>
                        <FormErrors errors={this.state.errors}/>
                        <div className="btn-group cols">
                            <div className="forgot-password col text-left">
                                <Link
                                    className="text-small"
                                    to={Paths.route('userRequestReset')}>
                                    Forgot password?
                                </Link>
                            </div>
                            <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={this.state.disabled}>
                                Continue
                            </button>
                        </div>
                    </form>
                </div>
                <div className="request-access text-small">
                    Not a member? <Link to={Paths.route('accessRequest')}>Request Access</Link>
                </div>
            </div>
        );
    }
}

export default Signin;
