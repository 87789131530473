module.exports = {

    // common validation rules for text
    commonText: {
        length: {
            min: 0,
            max: 128
        }
    },

    dateFormat: {
        format: 'MM/DD/YYYY'
    },
    
    password: {
        length: {
            min: 8,
            max: 128
        }
    }
};
