import React from 'react';

function Header({data}) {
    return (
        <div className="common-header">
            <h1 className="title">{data.title}</h1>
        </div>
    );
}

export default Header;
