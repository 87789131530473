import React from 'react';
import { FieldGroup, FieldGroupSelect, FileDrop } from 'app/components/common';
import { fieldErrors } from 'app/lib/forms';
import licenseTypes from 'app/data/licenseTypes';

function Fields({disabled, errors, formData, files, handleChange, liftState}) {
    return (
        <div className="form-group">
            <FieldGroup
                label="License Number"
                errors={fieldErrors('license_number', errors)}>
                <input type="text"
                    name="license_number"
                    value={formData.license_number}
                    onChange={handleChange}
                    disabled={disabled}
                    required autoFocus/>
            </FieldGroup>
            <FieldGroupSelect
                label="License Type"
                name="license_type"
                optionMap={licenseTypes}
                formData={formData}
                handleChange={handleChange}
                disabled={disabled}
                errors={errors}
            />
            <FieldGroup
                label="License Expiration Date"
                errors={fieldErrors('license_expiration_date', errors)}>
                <input type="text"
                    name="license_expiration_date"
                    value={formData.license_expiration_date}
                    onChange={handleChange}
                    disabled={disabled}
                    placeholder="MM/DD/YYYY"
                    required/>
            </FieldGroup>
            <FieldGroup
                label="License Document"
                errors={fieldErrors('license_file', errors)}>
                <FileDrop
                    files={files}
                    onDrop={files => liftState({files})}
                    onRemove={() => liftState({files: []})}
                    disabled={disabled}
                    options={{
                        accept: ['application/pdf', 'image/jpeg', 'image/png'],
                        showPreview: false
                    }}
                />
            </FieldGroup>
            <FieldGroup
                label="Legal Business Name"
                errors={fieldErrors('business_name', errors)}>
                <input type="text"
                    name="business_name"
                    value={formData.business_name}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldGroup>
            <FieldGroup
                label="First Name"
                errors={fieldErrors('first_name', errors)}>
                <input type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldGroup>
            <FieldGroup
                label="Last Name"
                errors={fieldErrors('last_name', errors)}>
                <input type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldGroup>
            <FieldGroup
                label="Email Address"
                errors={fieldErrors('email', errors)}>
                <input type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldGroup>
            <FieldGroup
                label="Phone Number"
                errors={fieldErrors('phone_number', errors)}>
                <input type="tel"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldGroup>
        </div>
    );
}

export default Fields;
