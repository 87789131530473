import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Paths } from 'app/config';
import { Products, Profiles } from '.';

function Search() {
    return (
        <div className="search-results container">
            <Switch>
                <Route
                    path={Paths.absolute('searchProducts', ':query')}
                    render={routerProps => <Products {...routerProps}/>}
                />
                <Route
                    path={Paths.absolute('searchProfiles', ':query')}
                    render={routerProps => <Profiles {...routerProps}/>}
                />
                <Redirect to={Paths.absolute('products')}/>
            </Switch>
        </div>
    );
}

export default Search;
