import React, { Component } from 'react';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { ContentMessage, Loading } from 'app/components/common';
import { Header, Item, Tabs } from '.';

class ServiceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            filteredData: []
        };
    }
    componentDidMount() {
        this.getServiceRequests();
    }
    componentDidUpdate(prevProps) {
        if (this.props.match.params.category !== prevProps.match.params.category) {
            this.setState({loading: true}, this.filterServiceRequests);
        }
    }
    getServiceRequests = () => {
        axios.get(Paths.service('serviceRequests')).then(r => {
            console.log('ServiceList :: getServiceRequests ::', r.data);
            this.setState({data: r.data}, this.filterServiceRequests);
        }).catch(error => {
            validate.dataErrors('ServiceList :: getServiceRequests [Error] ::', error);
            this.setState({loading: false});
        });
    }
    filterServiceRequests = () => {
        const category = this.props.match.params.category || '';
        let filteredData = this.state.data;
        if (category) filteredData = this.state.data.filter(o => o.category === category);
        this.setState({filteredData, loading: false});
    }
    render() {
        const { filteredData, loading } = this.state;
        return (
            <div className="service-catalog container">
                <Header {...this.props}/>
                <Tabs {...this.props}/>
                {loading && <Loading padded/>}
                {!loading && !!filteredData.length &&
                    <ul className="service-list list-expandable">
                        {filteredData.map(o =>
                            <Item {...this.props} key={o.id} data={o}/>
                        )}
                    </ul>
                }
                {!loading && !filteredData.length &&
                    <ContentMessage message="No service requests found." padded/>
                }
            </div>
        );
    }
}

export default ServiceList;
