import React from 'react';
import { FieldRow, FileDrop } from 'app/components/common';
import { timeDistance } from 'app/lib/helpers';
import { fieldErrors } from 'app/lib/forms';
import licenseTypes from 'app/data/licenseTypes';
import usStates from 'app/data/usStates';

const commonCaption1 = "As it appears on your license.";
const commonCaption2 = "As specified by your license.";

function Fields({errors, disabled, disabledFields, files, formData, handleChange, license, liftState}) {
    return (
        <div className="form-group">
            <FieldRow
                label="License Number *"
                caption={commonCaption1}
                errors={fieldErrors('number', errors)}>
                <input type="text"
                    placeholder="Type your license number."
                    name="number"
                    value={formData.number}
                    onChange={handleChange}
                    disabled={disabled || disabledFields.includes('number')}
                    required/>
            </FieldRow>
            <FieldRow select
                label="License Type *"
                caption={commonCaption2}
                name="type"
                optionMap={licenseTypes}
                formData={formData}
                handleChange={handleChange}
                disabled={disabled || disabledFields.includes('type')}
                errors={errors}
            />
            <FieldRow
                label="License Issue Date *"
                caption="The date your license became valid as specified by your license."
                errors={fieldErrors('issue_date', errors)}>
                <input type="text"
                    placeholder="MM/DD/YYYY"
                    name="issue_date"
                    value={formData.issue_date}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldRow>
            <FieldRow
                label="License Expiration Date *"
                caption="The date your license will become invalid as specified by your license."
                errors={fieldErrors('expiration_date', errors)}>
                <input type="text"
                    placeholder="MM/DD/YYYY"
                    name="expiration_date"
                    value={formData.expiration_date}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldRow>
            <FieldRow
                label="License Document *"
                caption="Drop your document file in the dotted area or click to browse."
                errors={fieldErrors('file', errors)}>
                <FileDrop
                    label={licenseFileLabel(files, license.file_timestamp)}
                    files={files}
                    onDrop={files => liftState({files})}
                    onRemove={() => liftState({files: []})}
                    disabled={disabled}
                    options={{
                        accept: ['application/pdf', 'image/jpeg', 'image/png'],
                        showPreview: false
                    }}
                />
            </FieldRow>
            <FieldRow
                label="Legal Business Name *"
                caption={commonCaption1}
                errors={fieldErrors('business_name', errors)}>
                <input type="text"
                    placeholder="Type your legal business name."
                    name="business_name"
                    value={formData.business_name}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldRow>
            <FieldRow
                label="Address Line 1 *"
                caption={commonCaption1}
                errors={fieldErrors('address1', errors)}>
                <input type="text"
                    placeholder="Type the first line of your address."
                    name="address1"
                    value={formData.address1}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldRow>
            <FieldRow
                label="Address Line 2"
                caption={commonCaption1}
                errors={fieldErrors('address2', errors)}>
                <input type="text"
                    placeholder="Type the second line of your address."
                    name="address2"
                    value={formData.address2}
                    onChange={handleChange}
                    disabled={disabled}/>
            </FieldRow>
            <FieldRow
                label="City *"
                caption={commonCaption1}
                errors={fieldErrors('city', errors)}>
                <input type="text"
                    placeholder="Type your city."
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldRow>
            <FieldRow select
                label="State *"
                caption={commonCaption2}
                name="state"
                optionMap={usStates}
                formData={formData}
                handleChange={handleChange}
                disabled={disabled}
                errors={errors}
            />
            <FieldRow
                label="Zip Code *"
                caption={commonCaption1}
                errors={fieldErrors('zip', errors)}>
                <input type="text"
                    placeholder="Type your zip code."
                    name="zip"
                    value={formData.zip}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldRow>
        </div>
    );
}

function licenseFileLabel(files, file_timestamp) {
    if (files[0]) return files[0].name;
    if (file_timestamp?._seconds) {
        return `License document uploaded ${timeDistance(file_timestamp._seconds)} ago`;
    }
    return 'Drop your file here or click to browse';
}

export default Fields;
