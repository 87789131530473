import React, { Component } from 'react';
import { EmailGate, ProfileGate } from '.';

class Gateway extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gates: ['emailGate', 'profileGate']
        };
    }
    componentDidUpdate() {
        if (!this.state.gates.length) {
            this.props.liftState({gated: false});
        }
    }
    removeGate = gate => {
        const arr = [...this.state.gates];
        const gates = arr.filter(g => g !== gate);
        this.setState({gates});
    }
    render() {
        const gates = [
            ['emailGate', <EmailGate {...this.props} removeGate={this.removeGate}/>],
            ['profileGate', <ProfileGate {...this.props} removeGate={this.removeGate}/>]
        ];
        for (const gate of gates) {
            if (this.state.gates.includes(gate[0])) {
                return gate[1];
            }
        }
        return null;
    }
}

export default Gateway;
