import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AccessRequests, Licenses, Menu, UpdateEmailLists, UserLookup } from '.';
import { Paths } from 'app/config';

function Logout({user}) {
    return (
        <div className="logout cols">
            <Menu/>
            <div className="col">
                <div className="container">
                    <Switch>
                        <Route
                            path={Paths.absolute('logoutRequests')}
                            render={routerProps => <AccessRequests {...routerProps}/>}
                        />
                        <Route
                            path={Paths.absolute('logoutLicenses')}
                            render={routerProps => <Licenses {...routerProps} user={user}/>}
                        />
                        <Route
                            path={Paths.absolute('logoutLookup')}
                            render={routerProps => <UserLookup {...routerProps}/>}
                        />
                        <Route
                            path={Paths.absolute('logoutUpdateEmailLists')}
                            render={routerProps => <UpdateEmailLists {...routerProps}/>}
                        />
                        <Redirect to={Paths.absolute('logoutRequests')}/>
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default Logout;
