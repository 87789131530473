import React from 'react';
import { FieldRow } from 'app/components/common';
import { fieldErrors } from 'app/lib/forms';

function Fields({errors, disabled, formData, handleChange}) {
    return (
        <div className="form-group">
            <FieldRow
                label="First Name *"
                caption="For contact purposes only."
                errors={fieldErrors('first_name', errors)}>
                <input type="text"
                    placeholder="Type your first name."
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldRow>
            <FieldRow
                label="Last Name *"
                caption="For contact purposes only."
                errors={fieldErrors('last_name', errors)}>
                <input type="text"
                    placeholder="Type your last name."
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldRow>
            <FieldRow
                label="Email Address *"
                caption="Your email address allows you to sign in and receive important notifications."
                errors={fieldErrors('email', errors)}>
                <input type="email"
                    placeholder="Type your email address."
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldRow>
            <FieldRow
                label="Phone Number *"
                caption="For contact purposes only."
                errors={fieldErrors('phone_number', errors)}>
                <input type="tel"
                    placeholder="Type your phone number."
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldRow>
        </div>
    );
}

export default Fields;
