import React from 'react';
import { Paths } from 'app/config';
import { messagePath } from 'app/components/Messages';

function Actions({data, profile, history, user}) {
    return (
        <div className="actions text-right">
            {profile && profile.uid === user.user_id &&
                <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => history.push(Paths.route('serviceRequestEdit', data.id))}>
                    Edit
                </button>
            }
            {profile && profile.uid !== user.user_id &&
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => history.push(messagePath(user.user_id, profile))}
                    disabled={!profile}>
                    Send Message
                </button>
            }
        </div>
    );
}

export default Actions;
