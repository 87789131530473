import React from 'react';

const EvernugLogo = () => (
    <svg role="img" aria-label="evernug logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.5 23">
        <g>
            <path d="M0.2,22.5C0.1,22.3,0,22.1,0,21.9V1.2c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.3-0.2,0.6-0.2h15.1
                c0.2,0,0.4,0.1,0.6,0.2s0.2,0.4,0.2,0.6v3.2c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2H5.8v4.1h9.4c0.2,0,0.4,0.1,0.6,0.2
                C16,9.6,16,9.8,16,10v2.9c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2H5.8v4.2h10.4c0.2,0,0.4,0.1,0.6,0.2s0.2,0.4,0.2,0.6v3.2
                c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2H0.8C0.6,22.7,0.4,22.6,0.2,22.5z"/>
            <path d="M26,22.4c-0.2-0.2-0.4-0.4-0.5-0.7L19,1.3L19,1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.2,0.5-0.2h4.2
                c0.3,0,0.6,0.1,0.8,0.3C24.9,0.8,25,1,25.1,1.2l4.4,14.5l4.4-14.5C33.9,1,34,0.7,34.2,0.6c0.2-0.2,0.5-0.3,0.8-0.3h4.2
                c0.2,0,0.4,0.1,0.5,0.2s0.2,0.3,0.2,0.5c0,0.1,0,0.2,0,0.3l-6.5,20.5c-0.1,0.3-0.2,0.5-0.4,0.7c-0.2,0.2-0.5,0.3-0.9,0.3h-5.2
                C26.5,22.7,26.2,22.6,26,22.4z"/>
            <path d="M43,22.5c-0.2-0.2-0.2-0.3-0.2-0.6V1.2c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.3-0.2,0.6-0.2h15.1
                c0.2,0,0.4,0.1,0.6,0.2s0.2,0.4,0.2,0.6v3.2c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2H48.5v4.1H58c0.2,0,0.4,0.1,0.6,0.2
                c0.2,0.2,0.2,0.4,0.2,0.6v2.9c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-9.4v4.2h10.4c0.2,0,0.4,0.1,0.6,0.2
                s0.2,0.4,0.2,0.6v3.2c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2H43.6C43.3,22.7,43.2,22.6,43,22.5z"/>
            <path d="M63.5,22.5c-0.2-0.2-0.2-0.3-0.2-0.6V1.2c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.3-0.2,0.6-0.2h8.8
                c2.8,0,5,0.6,6.6,1.9c1.6,1.3,2.4,3.1,2.4,5.4c0,1.5-0.3,2.8-1,3.8s-1.6,1.9-2.9,2.4l4.3,7.8c0.1,0.1,0.1,0.2,0.1,0.4
                c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2h-4.5c-0.6,0-1.1-0.3-1.3-0.9l-3.5-6.9h-3.1v7c0,0.2-0.1,0.4-0.2,0.6
                c-0.2,0.2-0.4,0.2-0.6,0.2h-4.4C63.9,22.7,63.7,22.6,63.5,22.5z M72.9,10.2c0.9,0,1.6-0.2,2.1-0.7c0.5-0.5,0.7-1.1,0.7-1.9
                s-0.2-1.5-0.7-2c-0.5-0.5-1.2-0.7-2.1-0.7h-3.6v5.3H72.9z"/>
            <path d="M85.8,22.5c-0.2-0.2-0.2-0.3-0.2-0.6V1.2c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.3-0.2,0.6-0.2H90
                c0.5,0,0.9,0.2,1.1,0.6l7.6,11.9V1.2c0-0.2,0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2h4.1c0.2,0,0.4,0.1,0.6,0.2s0.2,0.4,0.2,0.6v20.7
                c0,0.2-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2h-3.6c-0.5,0-0.9-0.2-1.1-0.6l-7.5-11.5v11.3c0,0.2-0.1,0.4-0.2,0.6
                c-0.2,0.2-0.4,0.2-0.6,0.2h-4.1C86.1,22.7,85.9,22.6,85.8,22.5z"/>
            <path d="M111,20.8c-1.7-1.5-2.6-3.7-2.6-6.6V1.2c0-0.2,0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2h4.4c0.2,0,0.4,0.1,0.6,0.2
                s0.2,0.4,0.2,0.6v13c0,1.3,0.3,2.3,0.9,3c0.6,0.7,1.5,1,2.7,1c1.2,0,2.1-0.3,2.7-1c0.6-0.7,0.9-1.7,0.9-3v-13
                c0-0.2,0.1-0.4,0.2-0.6s0.4-0.2,0.6-0.2h4.4c0.2,0,0.4,0.1,0.6,0.2s0.2,0.4,0.2,0.6v13.1c0,2.9-0.9,5.2-2.6,6.6
                c-1.7,1.5-4.1,2.2-7.1,2.2C115.1,23,112.7,22.3,111,20.8z"/>
            <path d="M134,20.8c-1.8-1.5-2.7-3.7-2.8-6.4c0-0.6,0-1.6,0-2.9c0-1.3,0-2.3,0-2.9c0.1-2.7,1-4.8,2.8-6.3
                s4.2-2.2,7.2-2.2c2,0,3.8,0.3,5.4,1c1.5,0.7,2.7,1.5,3.5,2.5c0.8,1,1.2,2.1,1.3,3.2c0,0.2-0.1,0.4-0.2,0.5s-0.3,0.2-0.5,0.2h-5
                c-0.2,0-0.4,0-0.5-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.6-0.7-1.1-1.2-1.5c-0.6-0.4-1.4-0.6-2.4-0.6c-2.5,0-3.8,1.3-3.8,3.8
                c0,0.6,0,1.5,0,2.8c0,1.2,0,2.2,0,2.8c0.1,2.7,1.4,4.1,3.9,4.1c1.2,0,2.2-0.3,2.9-0.9c0.7-0.6,1.1-1.6,1.1-2.8v-0.6h-2.8
                c-0.2,0-0.4-0.1-0.6-0.2s-0.2-0.4-0.2-0.6v-2.5c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.4-0.2,0.6-0.2h8.1c0.2,0,0.4,0.1,0.6,0.2
                c0.2,0.2,0.2,0.4,0.2,0.6v3.7c0,1.8-0.4,3.4-1.3,4.7c-0.8,1.3-2,2.3-3.6,3c-1.5,0.7-3.3,1-5.4,1C138.2,23,135.8,22.3,134,20.8z"/>
        </g>
    </svg>
);

export default EvernugLogo;
