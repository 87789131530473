import React, { Component } from 'react';
import queryString from 'query-string';
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'app/lib/axios';
import { Paths } from 'app/config';
import { FormErrors, FieldGroup, Loading } from 'app/components/common';
import { fieldErrors } from 'app/lib/forms';
import validate from 'app/lib/validate';
import { connect } from  'react-redux';
import { setConfig } from 'redux/actions/config';

class Password extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            email: '',
            errors: null,
            loading: true,
            query: {},
            refreshed: false
        };
    }
    componentDidMount() {
        this.setState({query: queryString.parse(window.location.search)}, this.verifyCode);
    }
    verifyCode = async () => {
        try {
            const email = await firebase.auth().verifyPasswordResetCode(this.state.query.code);
            console.log('Password :: verifyCode ::', email);
            this.setState({email, loading: false});
        }
        catch(err) {
            console.log('Password :: verifyCode [Error] ::', err);
            const { email } = this.props.match.params;
            if (this.state.refreshed) {
                this.props.history.push(Paths.route('accessExpired', email));
            }
            else this.refreshExpiredCode(email);
        }
    }
    refreshExpiredCode = email => {
        axios.post(Paths.service('accessExpired', 'refresh'), {email}).then(r => {
            console.log('Password :: refreshExpiredCode ::', r.data);
            this.setState({
                query: {...this.state.query, code: r.data.code},
                refreshed: true
            }, this.verifyCode);
        }).catch(error => {
            validate.dataErrors('Password :: refreshExpiredCode [Error] ::', error);
        });
    }
    confirmPasswordReset = password => {
        firebase.auth().confirmPasswordReset(this.state.query.code, password)
        .then(r => {
            console.log('Password :: confirmPasswordReset ::', r);
            this.signInUser(password);
        })
        .catch(err => {
            console.log('Password :: confirmPasswordReset [Error] ::', err);
            this.setState({disabled: false, errors: [validate.firebaseErrorHandler(err.code)]});
        });
    }
    signInUser = password => {
        firebase.auth().signInWithEmailAndPassword(this.state.email, password)
        .then(user => {
            console.log('Password :: signInUser ::', user);
            this.props.setConfig({newUser: true});
        })
        .catch(err => {
            console.log('Password :: signInUser [Error] ::', err);
            this.setState({disabled: false, errors: [validate.firebaseErrorHandler(err.code)]});
        });
    }
    validateForm = e => {
        e.preventDefault();
        this.setState({disabled: true, errors: null});
        const errors = [
            ...validate.password('password', e.target.password.value)
        ];
        if (errors.length) this.setState({disabled: false, errors});
        else this.confirmPasswordReset(e.target.password.value);
    }
    render() {
        const { disabled, errors, loading } = this.state;
        return loading ? <Loading padded/> : (
            <div className="form-container centered">
                <div className="card">
                    <form className="inner" onSubmit={this.validateForm}>
                        <h2 className="form-title">Create Password</h2>
                        <FieldGroup
                            label="Password"
                            errors={fieldErrors('password', errors)}>
                            <input
                                type="password"
                                name="password"
                                disabled={disabled}
                                autoFocus required/>
                        </FieldGroup>
                        <FormErrors errors={errors}/>
                        <div className="btn-group">
                            <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={disabled}>
                                Continue
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = { setConfig };
export default connect(null, mapDispatchToProps)(Password);
