import React from 'react';
import { CharacterLimit, FieldGroup, FieldGroupSelect } from 'app/components/common';
import { fieldErrors } from 'app/lib/forms';
import serviceCategories from 'app/data/serviceCategories';

function Fields(props) {
    const { disabled, errors, formData, handleChange } = props;
    return (
        <div className="form-group">
            <FieldGroup
                label="Title"
                errors={fieldErrors('title', errors)}>
                <input type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    disabled={disabled}
                    maxLength="80"
                    autoFocus required/>
            </FieldGroup>
            <FieldGroup
                label="Description"
                errors={fieldErrors('description', errors)}
                hint={<CharacterLimit value={formData.description} limit={500}/>}>
                <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    disabled={disabled}
                    required/>
            </FieldGroup>
            <FieldGroupSelect
                {...props}
                label="Service Type"
                name="category"
                optionMap={serviceCategories}
                handleChange={handleChange}
            />
        </div>
    );
}

export default Fields;
