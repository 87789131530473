import React, { useEffect, useState } from 'react';
import { Paths } from 'app/config';
import { getResizedImageURL } from 'app/lib/firebaseMethods';

function ImageAsBackground({image, path, pathParams = '', size = 900}) {
    const [src, setSrc] = useState(null);

    useEffect(() => {
        if (image && path) {
            const bucket = Paths.storage(path, pathParams);
            getResizedImageURL(bucket, image, size).then(s => setSrc(s));
        }
    }, [image, path, pathParams, size]);

    return (
        <span
            className="image image-as-background"
            style={src ? {backgroundImage: `url(${src})`} : {}}
        />
    );
}

export default ImageAsBackground;
