import React, { Component } from 'react';
import { Paths } from 'app/config';
import { getResizedImageURL } from 'app/lib/firebaseMethods';

class ProfileImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {
                paddingTop: props.imageMetadata ? calculatePaddingTop(props.imageMetadata) : '75%'
            }
        }
    }
    componentDidMount() {
        const { data } = this.props;
        if (data.image) {
            const bucket = Paths.storage('profileImage', data.uid);
            getResizedImageURL(bucket, data.image, 600).then(src => {
                this.setState({style: {
                    ...this.state.style,
                    backgroundImage: `url(${src})`
                }});
            });
        }
    }
    render = () => <div className="metadata-image" style={this.state.style}/>;
}

function calculatePaddingTop(metadata) {
    const { width: w, height: h } = metadata;
    const decimal = w > h ? h / w : w / h;
    return decimal * 100 + '%';
}

export default ProfileImage;
