import React from 'react';
import Select, { selectProps } from 'app/components/common/Select';
import { FieldGroup } from 'app/components/common';
import { fieldErrors } from 'app/lib/forms';

function FieldGroupSelect(props) {
    const { disabled, errors, formData, handleChange, label, name, optionMap } = props;
    const options = Object.keys(optionMap).map(k => ({value: k, label: optionMap[k]}));
    return (
        <FieldGroup
            label={label}
            errors={fieldErrors(name, errors)}>
            <Select
                {...selectProps}
                {...props.selectProps}
                options={options}
                value={options.find(o => o.value === formData[name])}
                onChange={selected => handleChange({
                    target: {
                        name,
                        value: selected ? selected.value : null
                    }
                })}
                isDisabled={disabled}
            />
        </FieldGroup>
    );
}

export default FieldGroupSelect;
