import React, { useState } from 'react';
import { fromUnixTime, format } from 'date-fns';
import { IconChevronDown, IconChevronUp } from 'app/assets/icons';
import { Attribute, Footer, Indicator, LicenseFile } from '.';
import licenseTypes from 'app/data/licenseTypes';

function ExpandableItem(props) {
    const [expanded, setExpanded] = useState(false);
    function handleClick(e) {
        e.preventDefault();
        setExpanded(!expanded);
    }
    const { request: r } = props;
    return (
        <li className="item card">
            <div className="header cols" onClick={handleClick}>
                <Indicator status={r.status}/>
                <h3 className="heading col">
                    <a href="./" onClick={e => e.preventDefault()}>
                        {r.business_name}
                    </a>
                </h3>
                <div className="col text-right">
                    {format(fromUnixTime(r.created._seconds), "MMM d, yyyy 'at' h:mm a")}
                </div>
                <a href="./" className="expand-icon icon" onClick={handleClick}>
                    {expanded ? <IconChevronUp/> : <IconChevronDown/>}
                </a>
            </div>
            {expanded && <>
                <div className="body">
                    <ul className="list-attributes">
                        <Attribute label="License Number" value={r.license_number}/>
                        <Attribute label="License Type" value={licenseTypes[r.license_type]}/>
                        <Attribute label="License Expiration Date" value={r.license_expiration_date}/>
                        <Attribute label="License File" value={<LicenseFile fileName={r.license_file}/>}/>
                        <Attribute label="Legal Business Name" value={r.business_name}/>
                        <Attribute label="First Name" value={r.first_name}/>
                        <Attribute label="Last Name" value={r.last_name}/>
                        <Attribute label="Email Address" value={<a href={`mailto:${r.email}`}>{r.email}</a>}/>
                        <Attribute label="Phone Number" value={r.phone_number}/>
                    </ul>
                </div>
                <Footer {...props}/>
            </>}
        </li>
    );
}

export default ExpandableItem;
