import React from 'react';
import { IconStorefront } from 'app/assets/icons';
import categories from 'app/data/licenseCategories';

function Header({match}) {
    return (
        <div className="common-header cols">
            <h2 className="col has-icon">
                <span className="icon has-success"><IconStorefront/></span>
                Profiles
                {match.params.category &&
                    <span className="badge badge-info">
                        {categories[match.params.category].label}
                    </span>
                }
            </h2>
        </div>
    );
}

export default Header;
