import React from 'react';
import { Link } from 'react-router-dom';
import { Paths } from 'app/config';
import { Footer } from '.';

function Home({history}) {
    return (
        <div className="home">
            <div className="introduction">
                <h1 className="heading">
                    Evernug is a free-range marketplace for licensed cannabis
                    farmers, nurseries, and manufacturers. Discover products,
                    sell wholesale, and form new partnerships along the way.
                </h1>
                <div className="content-spacer"></div>
                <h2 className="heading">
                    New to the club?
                </h2>
                <button
                    type="button"
                    className="btn btn-large btn-info"
                    onClick={() => history.push(Paths.route('accessRequest'))}>
                    Request Access
                </button>
            </div>
            <div className="body">
                <div className="open-letter">
                    <h1 className="section text-center"><em>Simplifying Cannabis B2B in California</em></h1>
                    <h3 className="section">As someone who works with a small cannabis manufacturer in California I understand first-hand how hard it can be to source the right material when you need it.</h3>
                    <h3 className="section">At the time, I was looking to find a specific distillate for a new product we were working on. I took the search online and found that existing platforms made it difficult for operators to work with other operators, while at the same time fragmenting the industry with high fees. These platforms have gatekeepers who tell you what you can and can't do, and who you can and can't talk to.</h3>
                    <h3 className="section">Arbitrary rules like these constrict California Cannabis and weaken the legal network. When you consider the position our current legal industry is in, it makes it hard to agree with these tactics. I set out to find a better way.</h3>
                    <h3 className="section">Freedom of movement gives us the ability to discover, find opportunity, and grow. Evernug was created to remove the red tape and give legal operators like you access to an open marketplace, no matter where you are in the state.</h3>
                    <h3 className="section">Whether you want to form a new relationship with a farm, sell your oil to other manufacturers, or create your next partnership, you have the power to do it on your terms with Evernug.</h3>
                    <h3 className="section"><Link to={Paths.route('accessRequest')}>Request Access</Link> to sign up. Once verified you're set up with your own account. You can then <Link to={Paths.route('userSignin')}>sign in</Link> and do business with anyone else in California who's also on Evernug.</h3>
                    <h3 className="section">I look forward to seeing you in the club,</h3>
                    <h2><em>-Rich AK</em></h2>
                </div>
                <div className="introduction">
                    <h2 className="heading">
                        Ready to join the club?
                    </h2>
                    <button
                        type="button"
                        className="btn btn-large btn-info"
                        onClick={() => history.push(Paths.route('accessRequest'))}>
                        Request Access
                    </button>
                    <div className="content-spacer"></div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Home;
