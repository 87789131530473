import React, { Component } from 'react';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths, textmap } from 'app/config';

class Expired extends Component {
    componentDidMount() {
        this.resendLink(this.props.match.params.email);
    }
    resendLink = email => {
        axios.post(Paths.service('accessExpired', 'resend'), {
            email, message: textmap.accessRequest.email.approved
        }).then(r => {
            console.log('RequestAccess/Expired :: resendLink ::', r.data);
        }).catch(error => {
            validate.dataErrors('RequestAccess/Expired :: resendLink [Error] ::', error);
        });
    }
    render() {
        return (
            <div className="card message-card readable-text text-center">
                <h2 className="common-header content-message">
                    {textmap.accessRequest.expired.title}
                </h2>
                <div className="line-height">
                    {textmap.accessRequest.expired.description}
                </div>
            </div>
        );
    }
}

export default Expired;
