import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Paths } from 'app/config';
import { getResizedImageURL } from 'app/lib/firebaseMethods';

function Image({product}) {
    const [src, setSrc] = useState(null);

    useEffect(() => {
        if (product.images[0]) {
            const bucket = Paths.storage('productImage', product.uid);
            getResizedImageURL(bucket, product.images[0], 600).then(s => setSrc(s));
        }
    }, [product]);

    return (
        <Link
            className="image"
            to={Paths.route('productHome', product.id)}
            style={src ? {
                backgroundImage: `url(${src})`,
                backgroundSize: 'contain'
            } : {}}
        />
    );
}

export default Image;
