import React from 'react';
import { fromUnixTime, formatDistance } from 'date-fns';

function Expiration({timestamp}) {
    const tenDaysAgo = Date.now() - 86400 * 1000 * 10;
    return (
        <span className="expiration">
            &nbsp;/ expires in {formatDistance(fromUnixTime(timestamp), tenDaysAgo, {includeSeconds: true})}
        </span>
    );
}

export default Expiration;
