import React from 'react';
import { Paths } from 'app/config';
import { IconServiceBell } from 'app/assets/icons';

function Header({history}) {
    return (
        <div className="common-header cols">
            <h2 className="col has-icon">
                <span className="icon has-success"><IconServiceBell/></span>
                Service Requests
            </h2>
            <button
                type="button"
                className="btn btn-primary"
                onClick={() => history.push(Paths.route('serviceRequestAdd'))}>
                Create Service Request
            </button>
        </div>
    );
}

export default Header;
