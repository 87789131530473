export const SET_ALERT = 'SET_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';

export function setAlert(alert) {
    return {
        type: SET_ALERT,
        alert
    };
}
export function clearAlert() {
    return {
        type: CLEAR_ALERT
    };
}
