import React from 'react';

function Actions({disabled, history, SkipButton}) {
    return (
        <div className="btn-group">
            {SkipButton ? <SkipButton/> :
                <button
                    type="button"
                    className="btn btn-outline-primary"
                    disabled={disabled}
                    onClick={history.goBack}>
                    Cancel
                </button>
            }
            <button
                type="submit"
                className="btn btn-primary"
                disabled={disabled}>
                Save Changes
            </button>
        </div>
    );
}

export default Actions;
