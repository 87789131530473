import React, { Component } from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import { FormErrors, FieldGroup, Loading } from 'app/components/common';
import { fieldErrors } from 'app/lib/forms';
import { Paths } from 'app/config';
import validate from 'app/lib/validate';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            complete: false,
            disabled: false,
            errors: null,
            loading: true,
            query: {}
        };
    }
    componentDidMount() {
        this.setState({query: queryString.parse(window.location.search)}, this.verifyCode);
    }
    verifyCode = async () => {
        try {
            const email = await firebase.auth().verifyPasswordResetCode(this.state.query.code);
            console.log('ResetPassword :: verifyCode ::', email);
            this.setState({loading: false});
        }
        catch(err) {
            console.log('ResetPassword :: verifyCode [Error] ::', err);
            this.props.history.push(Paths.absolute('userRequestReset') + '?expired');
        }
    }
    confirmPasswordReset = password => {
        firebase.auth().confirmPasswordReset(this.state.query.code, password)
        .then(r => {
            console.log('ResetPassword :: confirmPasswordReset ::', r);
            this.setState({complete: true});
        })
        .catch(err => {
            console.log('ResetPassword :: confirmPasswordReset [Error] ::', err);
            this.setState({disabled: false, errors: [validate.firebaseErrorHandler(err.code)]});
        });
    }
    validateForm = e => {
        e.preventDefault();
        this.setState({disabled: true, errors: null});
        const errors = [
            ...validate.password('password', e.target.password.value)
        ];
        if (errors.length) this.setState({disabled: false, errors});
        else this.confirmPasswordReset(e.target.password.value);
    }
    render() {
        const { complete, disabled, errors, loading } = this.state;
        return loading ? <Loading padded/> : <>
            {!complete &&
                <div className="form-container centered">
                    <div className="card">
                        <form className="inner" onSubmit={this.validateForm}>
                            <h2 className="form-title">Reset Password</h2>
                            <p className="sub-heading">
                                Please type your new password below.
                            </p>
                            <FieldGroup
                                errors={fieldErrors('password', errors)}>
                                <input
                                    type="password"
                                    name="password"
                                    disabled={disabled}
                                    autoFocus required/>
                            </FieldGroup>
                            <FormErrors errors={errors}/>
                            <div className="btn-group">
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                    disabled={disabled}>
                                    Continue
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            }
            {complete &&
                <div className="card message-card readable-text text-center">
                    <h2 className="common-header content-message">Success! Password Reset Complete.</h2>
                    Please <SignInLink/> to your account with your new password.
                </div>
            }
        </>;
    }
}

const SignInLink = () => (
    <Link to={Paths.route('userSignin')}>sign in</Link>
);

export default ResetPassword;
