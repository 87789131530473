import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Edit, Home } from '.';
import { Paths } from 'app/config';

function Profile({user}) {
    return (
        <div className="profile container">
            <Switch>
                <Route
                    path={Paths.absolute('profileEdit')}
                    render={routerProps => <Edit user={user} {...routerProps}/>}
                />
                <Route
                    path={Paths.absolute('profileHome', ':id')}
                    render={routerProps => <Home user={user} {...routerProps}/>}
                />
                <Redirect to={Paths.absolute('profiles')}/>
            </Switch>
        </div>
    );
}

export default Profile;
