import React from 'react';
import axios from 'app/lib/axios';
import { Paths } from 'app/config';
import { IconServiceBell } from 'app/assets/icons';
import { CommonForm, FormErrors } from 'app/components/common';
import { Actions, Fields } from '.';
import validate from 'app/lib/validate';

class Form extends CommonForm {
    state = {
        ...this.state,
        formData: {
            title: this.props.data.title || '',
            description: this.props.data.description || '',
            category: this.props.data.category || ''
        }
    };
    validators = () => [
        ...validate.commonText('title', this.state.formData.title, {max: 80}),
        ...validate.commonText('description', this.state.formData.description, {max: 500}),
        ...validate.commonText('category', this.state.formData.category)
    ];
    submitForm = () => {
        const { action, data, history } = this.props;
        const method = action === 'edit' ? 'put' : 'post';
        axios[method](Paths.service('serviceRequests', data.id || ''), this.state.formData).then(r => {
            console.log('Services/AddEdit/Form :: submitForm ::', r.data);
            history.push(Paths.route('serviceRequests'));
        }).catch(error => {
            const errors = validate.dataErrors('Services/AddEdit/Form :: submitForm [Error] ::', error);
            this.setState({disabled: false, errors});
        });
    }
    deleteServiceRequest = () => {
        this.setState({disabled: true});
        const { data, history } = this.props;
        // delete document
        axios.delete(Paths.service('serviceRequests', data.id)).then(r => {
            console.log('Services/AddEdit/Form :: deleteServiceRequest ::', r.data);
            history.push(Paths.route('serviceRequests'));
        }).catch(error => {
            const errors = validate.dataErrors('Services/AddEdit/Form :: deleteServiceRequest [Error] ::', error);
            this.setState({disabled: false, errors});
        });
    }
    render() {
        const { action } = this.props;
        const { disabled, errors } = this.state;
        return (
            <div className="card">
                <form className="inner" onSubmit={this.handleSubmit}>
                    <h3 className="form-title-extended has-icon">
                        <span className="icon">
                            <IconServiceBell/>
                        </span>
                        {action === 'edit' ? 'Edit' : 'Create'} Service Request
                    </h3>
                    <div className="form-container">
                        <Fields
                            disabled={disabled}
                            errors={errors}
                            formData={this.state.formData}
                            handleChange={this.handleChange}
                            liftState={this.liftState}
                        />
                        <FormErrors errors={errors}/>
                        <Actions
                            {...this.props}
                            deleteServiceRequest={this.deleteServiceRequest}
                            disabled={disabled}
                        />
                    </div>
                </form>
            </div>
        );
    }
}

export default Form;
