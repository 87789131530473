import React, { useEffect, useRef } from 'react';
import { Message } from '.';

function Feed({activeThread, messages, profile}) {
    const messageFeed = useRef(null);

    useEffect(() => {
        const el = messageFeed.current;
        el.scrollTop = el.scrollHeight;
    }, [messages]);

    return (
        <div ref={messageFeed} className="feed">
            {messages.map(m =>
                <Message key={m.id}
                    message={m}
                    activeThread={activeThread}
                    profile={profile}
                />
            )}
            {!messages.length &&
                <div className="text-small text-muted">
                    Write your first message to <b>{activeThread.partner.business_name}</b>
                </div>
            }
        </div>
    );
}

export default Feed;
