import React from 'react';
import { Link } from 'react-router-dom';
import { Paths } from 'app/config';
import { IconExternalLink } from 'app/assets/icons';
import { ImageAsBackground } from 'app/components/common';

function Header({activeThread: t}) {
    return (
        <div className="header">
            <Link to={Paths.route('profileHome', t.partner.uid)}>
                <div className="profile-image">
                    <ImageAsBackground
                        image={t.partner.image}
                        path="profileImage"
                        pathParams={t.partner.uid}
                        size={300}
                    />
                </div>
                <div className="meta">
                    <h3 className="business-name ellipsis">
                        {t.partner.business_name}
                    </h3>
                    <span className="view-profile">
                        <span className="label">View Profile</span>
                        <span className="icon">
                            <IconExternalLink/>
                        </span>
                    </span>
                </div>
            </Link>
        </div>
    );
}

export default Header;
