import React from 'react';
import { Paths } from 'app/config';

function OwnerActions({history, data}) {
    return <>
        <button
            type="button"
            className="btn btn-primary"
            onClick={() => history.push(Paths.route('productEdit', data.id))}>
            Edit Product
        </button>
        <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => history.push(Paths.route('productAdd'))}>
            Add Product
        </button>
    </>;
}

export default OwnerActions;
