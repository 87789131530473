import { INCREMENT_CLICK_COUNT } from '../actions/clickCount';

const initialState = 0;

const clickCount = (state = initialState, action) => {
    switch (action.type) {

        case INCREMENT_CLICK_COUNT:
            return state + 1;

        default:
            return state;
    }
};

export default clickCount;
