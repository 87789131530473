module.exports = {

    canProductWrite: function(license_category) {
        return !['distributor', 'packaging', 'processor'].includes(license_category);
    },

    hasPermissions: function(user, requiredPerms) {
        return user.perms && Array.isArray(user.perms) &&
            requiredPerms.every(perm => user.perms.includes(perm));
    }
};
