import React, { useState } from 'react';
import { IconChevronDown, IconChevronUp } from 'app/assets/icons';
import { TimeDistance } from 'app/components/common';
import { Footer } from '.';
import serviceCategories from 'app/data/serviceCategories';

function Item(props) {
    const { data } = props;
    const [expanded, setExpanded] = useState(false);
    function handleClick(e) {
        e.preventDefault();
        setExpanded(!expanded);
    }
    return (
        <li className="item card">
            <div className="header cols" onClick={handleClick}>
                <div className={`badge ${data.category}`}>
                    {serviceCategories[data.category]}
                </div>
                <h3 className="col title">{data.title}</h3>
                <div className="timestamp">
                    <TimeDistance timestamp={data.created._seconds}/> ago
                </div>
                <a href="./" className="expand-icon icon" onClick={handleClick}>
                    {expanded ? <IconChevronUp/> : <IconChevronDown/>}
                </a>
            </div>
            {expanded && <>
                <div className="body">
                    <p className="description">{data.description}</p>
                </div>
                <Footer {...props} data={data}/>
            </>}
        </li>
    );
}

export default Item;
