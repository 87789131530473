import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { withToastManager } from 'react-toast-notifications';
import { toast } from 'app/lib/helpers';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { IconStorefront } from 'app/assets/icons';
import { CommonForm, FormErrors } from 'app/components/common';
import { Actions, Fields, ImageGroup } from '.';
import { deleteImageAllSizes, storeFile } from 'app/lib/firebaseMethods';

class Form extends CommonForm {
    state = {
        ...this.state,
        formData: {
            business_name: this.props.data.business_name || '',
            description: this.props.data.description || '',
            website_url: this.props.data.website_url || '',
            image: this.props.data.image || ''
        },
        imageToDelete: '',
        files: []
    };
    validators = () => [
        ...validate.commonText('business_name', this.state.formData.business_name),
        ...validate.commonText('description', this.state.formData.description, {max: 1000, required: false}),
        ...validate.websiteURL('website_url', this.state.formData.website_url, {required: false})
    ];
    submitForm = async () => {
        const { data, history } = this.props;
        const { files, formData, imageToDelete } = this.state;
        const payload = {...formData};
        const bucket = Paths.storage('profileImage', data.uid);
        const promises = [];
        // upload new image
        if (files.length) {
            const fileName = `${uuidv4()}_profile_image.jpg`;
            promises.push(storeFile(bucket, fileName, files[0]));
            payload.image = fileName;
        }
        // delete removed image
        if (imageToDelete) promises.push(...deleteImageAllSizes(bucket, imageToDelete));
        if (promises.length) await Promise.all(promises);
        // submit form
        axios.put(Paths.service('profile', data.id), payload).then(r => {
            console.log('Profile/Edit/Form :: submitForm ::', r.data);
            this.props.toastManager.add(...toast("Success! Your profile has been updated."));
            if (this.props.successCallback) this.props.successCallback();
            else history.push(Paths.route('profileHome', data.uid));
        }).catch(error => {
            const errors = validate.dataErrors('Profile/Edit/Form :: submitForm [Error] ::', error);
            this.setState({disabled: false, errors});
        });
    }
    markImageForDeletion = () => {
        const formData = {...this.state.formData};
        formData.image = '';
        this.setState({formData, imageToDelete: this.state.formData.image});
    }
    render() {
        const { disabled, errors } = this.state;
        return (
            <div className="card">
                <form className="inner" onSubmit={this.handleSubmit}>
                    <h3 className="form-title-extended has-icon">
                        <span className="icon">
                            <IconStorefront/>
                        </span>
                        Edit Profile
                    </h3>
                    <div className="cols">
                        <ImageGroup
                            {...this.state}
                            bucket={Paths.storage('profileImage', this.props.data.uid)}
                            markImageForDeletion={this.markImageForDeletion}
                            liftState={this.liftState}
                        />
                        <div className="col form-container">
                            <Fields
                                disabled={disabled}
                                errors={errors}
                                formData={this.state.formData}
                                handleChange={this.handleChange}
                            />
                            <FormErrors errors={errors}/>
                            <Actions {...this.props} disabled={disabled}/>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default withToastManager(Form);
