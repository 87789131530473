import React, { Component } from 'react';
import axios from 'app/lib/axios';
import validate from 'app/lib/validate';
import { Paths } from 'app/config';
import { Loading } from 'app/components/common';
import { Form } from '.';

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account: null,
            loading: true
        }
    }
    componentDidMount() {
        this.getAccount();
    }
    getAccount = () => {
        axios.get(Paths.service('account')).then(r => {
            console.log('Settings/Account :: getAccount ::', r.data);
            this.setState({account: r.data, loading: false});
        }).catch(error => {
            validate.dataErrors('Settings/Account :: getAccount [Error] ::', error);
        });
    }
    render() {
        const { account, loading } = this.state;
        return loading
            ? <Loading padded/>
            : <Form {...this.props} account={account}/>;
    }
}

export default Account;
