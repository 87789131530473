import React from 'react';
import { Paths } from 'app/config';
import { IconCannabisLeaf } from 'app/assets/icons';

function Header({history, profile, user}) {
    return (
        <div className="common-header has-border cols">
            <h2 className="col has-icon">
                <span className="icon has-success"><IconCannabisLeaf/></span>
                Products
            </h2>
            {profile.uid === user.user_id &&
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => history.push(Paths.route('productAdd'))}>
                    Add Product
                </button>
            }
        </div>
    );
}

export default Header;
