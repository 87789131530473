import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AddEdit, ServiceList } from '.';
import { Paths } from 'app/config';

function Services({user}) {
    return (
        <div className="services container">
            <Switch>
                <Route
                    path={Paths.absolute('serviceRequestAdd')}
                    render={routerProps => <AddEdit {...routerProps} user={user} action="add"/>}
                />
                <Route
                    path={Paths.absolute('serviceRequestEdit', ':id')}
                    render={routerProps => <AddEdit {...routerProps} user={user} action="edit"/>}
                />
                <Route
                    path={Paths.absolute('serviceRequests', ':category?')}
                    render={routerProps => <ServiceList {...routerProps} user={user}/>}
                />
                <Redirect to={Paths.absolute('serviceRequests')}/>
            </Switch>
        </div>
    );
}

export default Services;
