export default {

    accessRequest: {

        email: {
            approved: "We are excited to inform you that your request to join Evernug has been approved!",
            denied: "We are sorry to inform you that your request to join Evernug has been denied."
        },
        expired: {
            title: "Access Request Verification Expired",
            description: "Your access request has been approved however the link we've sent you has expired. Please check your email inbox for a new link which should arrive momentarily."
        }
    },

    auth: {

        requestReset: {
            title: "Request Password Reset",
            description: "Please submit your email address below to receive a link to reset your password.",
            successTitle: "Success!",
            successDescription: "Please check your email inbox to reset your password."
        }
    }
};
