export default {
    apiKey: "AIzaSyAOOK8Y_jx6zNEqRRpwpRu1XhS23BpDjqA",
    authDomain: "evernug-app.firebaseapp.com",
    databaseURL: "https://evernug-app.firebaseio.com",
    projectId: "evernug-app",
    storageBucket: "evernug-app.appspot.com",
    messagingSenderId: "752592894663",
    appId: "1:752592894663:web:293fe367c59329045d5ce2",
    measurementId: "G-D2VCQMZF1T"
};
