import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AddEdit, Home } from '.';
import { Paths } from 'app/config';

function Product({user}) {
    return (
        <div className="product container">
            <Switch>
                <Route
                    path={Paths.absolute('productAdd')}
                    render={routerProps => <AddEdit {...routerProps} user={user} action="add"/>}
                />
                <Route
                    path={Paths.absolute('productEdit', ':id')}
                    render={routerProps => <AddEdit {...routerProps} user={user} action="edit"/>}
                />
                <Route
                    path={Paths.absolute('productHome', ':id')}
                    render={routerProps => <Home {...routerProps} user={user}/>}
                />
                <Redirect to={Paths.absolute('products')}/>
            </Switch>
        </div>
    );
}

export default Product;
