import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'app/lib/axios';
import { Paths } from 'app/config';
import { IconCannabisLeaf } from 'app/assets/icons';
import { CommonForm, FormErrors } from 'app/components/common';
import { Actions, Fields, ImageGroup } from '.';
import validate from 'app/lib/validate';
import { deleteImageAllSizes, storeFile } from 'app/lib/firebaseMethods';

class Form extends CommonForm {
    state = {
        ...this.state,
        formData: {
            title: this.props.data.title || '',
            description: this.props.data.description || '',
            category: this.props.data.category || ''
        },
        images: this.props.data.images || [],
        imagesToDelete: [],
        files_1: [],
        files_2: [],
        files_3: [],
        files_4: []
    };
    bucket = Paths.storage('productImage', this.props.user.user_id);
    componentDidMount() {
        const { attributes } = this.props.data;
        const formData = {...this.state.formData};
        if (attributes) Object.keys(attributes).forEach(key => {
            formData[`attribute_${key}`] = attributes[key];
        });
        this.setState({formData});
    }
    validators = () => [
        ...validate.commonText('title', this.state.formData.title),
        ...validate.commonText('description', this.state.formData.description, {max: 1000}),
        ...validate.commonText('category', this.state.formData.category)
    ];
    getPayload = async () => {
        const formData = {...this.state.formData};
        const payload = {}
        const attributes = {};
        const images = await this.processImages();
        Object.keys(formData).forEach(key => {
            if (key.startsWith('attribute_')) {
                attributes[key.replace('attribute_', '')] = formData[key];
            }
            else payload[key] = formData[key];
        });
        return {...payload, attributes, images};
    }
    submitForm = async () => {
        const payload = await this.getPayload();
        const { action, data, history } = this.props;
        const method = action === 'edit' ? 'put' : 'post';
        axios[method](Paths.service('product', data.id || ''), payload).then(r => {
            console.log('Product/AddEdit/Form :: submitForm ::', r.data);
            history.push(Paths.route('productHome', r.data.id || data.id));
        }).catch(error => {
            const errors = validate.dataErrors('Product/AddEdit/Form :: submitForm [Error] ::', error);
            this.setState({disabled: false, errors});
        });
    }
    processImages = async () => {
        const { files_1, files_2, files_3, files_4, imagesToDelete } = this.state;
        const images = [];
        const promises = [];
        [files_1, files_2, files_3, files_4].forEach((files, i) => {
            // persist existing images
            if (this.state.images[i]) return images.push(this.state.images[i]);
            // store new images
            if (files.length) {
                const fileName = `${uuidv4()}_product_image.jpg`;
                promises.push(storeFile(this.bucket, fileName, files[0]));
                images.push(fileName);
            }
        });
        // delete removed images
        imagesToDelete.forEach(fileName => promises.push(...deleteImageAllSizes(this.bucket, fileName)));
        await Promise.all(promises);
        return images;
    }
    markImageForDeletion = fileName => {
        const imagesToDelete = [...this.state.imagesToDelete];
        const imgs = this.state.images.map(name => {
            if (name === fileName) imagesToDelete.push(fileName);
            return name === fileName ? '' : name;
        });
        this.setState({images: imgs, imagesToDelete});
    }
    deleteProduct = async () => {
        this.setState({disabled: true});
        const { data, history, user } = this.props;
        // delete images
        const promises = [];
        data.images.forEach(fileName => promises.push(...deleteImageAllSizes(this.bucket, fileName)));
        await Promise.all(promises);
        // delete document
        axios.delete(Paths.service('product', data.id)).then(r => {
            console.log('Product/AddEdit/Form :: deleteProduct ::', r.data);
            history.push(Paths.route('profileHome', user.user_id));
        }).catch(error => {
            const errors = validate.dataErrors('Product/AddEdit/Form :: deleteProduct [Error] ::', error);
            this.setState({disabled: false, errors});
        });
    }
    render() {
        const { action } = this.props;
        const { disabled, errors } = this.state;
        return (
            <div className="card">
                <form className="inner" onSubmit={this.handleSubmit}>
                    <h3 className="form-title-extended has-icon">
                        <span className="icon">
                            <IconCannabisLeaf/>
                        </span>
                        {action === 'edit' ? 'Edit' : 'Add'} Product
                    </h3>
                    <div className="cols">
                        <ImageGroup
                            {...this.state}
                            bucket={this.bucket}
                            markImageForDeletion={this.markImageForDeletion}
                            liftState={this.liftState}
                        />
                        <div className="col form-container">
                            <Fields
                                disabled={disabled}
                                errors={errors}
                                formData={this.state.formData}
                                handleChange={this.handleChange}
                                liftState={this.liftState}
                            />
                            <FormErrors errors={errors}/>
                            <Actions
                                {...this.props}
                                deleteProduct={this.deleteProduct}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default Form;
