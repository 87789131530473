import React from 'react';
import { Paths } from 'app/config';
import { IconCannabisLeaf, IconStorefront } from 'app/assets/icons';
import Select, { selectProps } from 'app/components/common/Select';

const sources = {
    products: {icon: IconCannabisLeaf, path: 'searchProducts', label: 'Products'},
    profiles: {icon: IconStorefront, path: 'searchProfiles', label: 'Profiles'}
};

function Header({history, location, match, source}) {
    const { searchQuery } = location.state;
    const options = Object.keys(sources).map(key => ({
        icon: sources[key].icon,
        label: sources[key].label,
        value: sources[key].path
    }));
    const formatOptionLabel = ({icon: Icon, label, value}) => (
        <div className="select-option cols">
            <span className="icon"><Icon/></span>
            <div className="col">{label}</div>
        </div>
    );
    const handleChange = ({value}) => {
        const state = {searchPath: value, searchQuery};
        history.push(Paths.route(value, match.params.query, {state}));
    };
    return (
        <div className="common-header">
            <div className="select-source">
                <label className="text-small">Searching</label>
                <Select
                    {...selectProps}
                    className={`${selectProps.className} floating`}
                    options={options}
                    value={options.find(o => o.value === sources[source].path)}
                    onChange={handleChange}
                    formatOptionLabel={formatOptionLabel}
                />
            </div>
            <span className="text-small">
                Showing results for: <b>"{searchQuery}"</b>
            </span>
        </div>
    );
}

export default Header;
