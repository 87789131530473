import { Paths } from 'app/config';

function messagePath(currentUserId, partnerProfile) {
    const timestamp = Date.now();
    return {
        pathname: Paths.absolute('messages'),
        state: {
            thread: {
                id: "new",
                uids: [partnerProfile.uid, currentUserId],
                updated: {_seconds: timestamp / 1000},
                partner: partnerProfile
            }
        }
    };
}

export default messagePath;
